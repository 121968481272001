<template>
  <div v-if="showControls" class="absolute top-2 right-2 z-index-1">
    <el-dropdown trigger="click">
      <div class="px-2">
        <font-awesome-icon :icon="['fas', 'ellipsis-v']" />
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-if="showToggle('testMode')" icon="el-icon-s-data" @click.native="toggleTestMode()"
          >View {{ testMode ? 'Live' : 'Test' }} Data</el-dropdown-item
        >
        <el-dropdown-item v-if="showToggle('confidential')" icon="el-icon-view" @click.native="toggleConfidential()"
          >Confidential Mode {{ confidential ? 'Off' : 'On' }}</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script lang="ts">
import { SET_CONFIDENTIAL, SET_TEST_MODE } from '@/store/mutation-types';
import { ValueOrGetter, RouteMeta } from '@/store/types';
import Vue from 'vue';
import { mapGetters } from 'vuex';

type Config = {
  confidential: boolean;
  testMode: boolean;
};

export default Vue.extend({
  name: 'DataControls',
  computed: {
    ...mapGetters(['confidential', 'testMode', 'evalBoolean']),
    config() {
      const { dataControls } = this.$route.meta as RouteMeta;
      return {
        confidential: this.evalBoolean(dataControls?.confidential),
        testMode: this.evalBoolean(dataControls?.testMode),
      };
    },
    showControls() {
      return Object.values(this.config as Config).some((v) => v);
    },
    showToggle() {
      return (key: keyof Config) => (this.config as Config)[key];
    },
  },
  methods: {
    toggleConfidential() {
      this.$store.commit(SET_CONFIDENTIAL, !this.confidential);
    },
    toggleTestMode() {
      this.$store.commit(SET_TEST_MODE, !this.testMode);
    },
  },
});
</script>
