/* eslint-disable object-curly-newline */
import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import adminService from '@/api/admin.service';
import AuthService from '@/api/auth.service';
import BlueprintService from '@/api/blueprint.service';
import OrgService from '@/api/org.service';
import SegmentService from '@/api/segment.service';
import UserService from '@/api/user.service';
import WebhookService from '@/api/webhook.service';
import { findBlueprintCouponIds, mockPaddleCoupons } from '@/helpers/embed-utils';
import EventBus from '@/helpers/event-bus';
import router from '@/router/router';
import * as actions from '@/store/action-types';
import { generateFetch } from '@/store/generators';
import * as mutations from '@/store/mutation-types';
import { LOAD_STATE, SAVE_STATE } from '@/store/shared';
import { ActionContext, IOrg, IssueUpdate, IState } from '@/store/types';
import StripeCustomer from './StripeCustomer';
interface IActionContext {
  commit: any;
  dispatch: any;
  state: IState;
  getters: any;
}

const register = async ({ commit, dispatch }: IActionContext, payload: any) => {
  commit(mutations.SET_AUTH_LOADING);
  try {
    const response = await UserService.create(payload);
    const { token } = response;
    localStorage.setItem('token', `${token}`);

    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    commit(mutations.SET_AUTH_SUCCESS, token);
    await dispatch(actions.SYNC_USER_RECORD);

    // window.heap.track('User Registration');
  } catch (error) {
    if (error.response?.data?.message?.includes('unique')) {
      EventBus.$emit('alert', 'Auth Error', 'An account with this email already exists.');
    }
    commit(mutations.SET_AUTH_ERROR);
    localStorage.removeItem('token');
  }
};

const login = async ({ commit, dispatch }: IActionContext, payload: any) => {
  commit(mutations.SET_AUTH_LOADING);
  try {
    commit(mutations.SET_AUTH_2FA_ERROR_MESSAGE, null);

    const response: { token?: string; twoFactorAuthRequired?: string } = await axios.post('/auth/login', payload);

    if (response.twoFactorAuthRequired) {
      commit(mutations.SET_AUTH_2FA_REQUIRED);
      return;
    }

    const { token } = response;
    localStorage.setItem('token', `${token}`);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    commit(mutations.SET_AUTH_SUCCESS, token);
    await dispatch(actions.SYNC_USER_RECORD);

    // window.heap.track('User Login');
  } catch (error) {
    if (payload.code) {
      // submitting 2FA

      const twoFactorErrorMsg = error.response?.data || 'The code you entered is not valid.';
      EventBus.$emit('alert', '2FA Failed', twoFactorErrorMsg);
      commit(mutations.SET_AUTH_2FA_ERROR_MESSAGE, twoFactorErrorMsg);
    } else {
      EventBus.$emit('alert', 'Login Failed', 'Please check your credentials and try again.');
      commit(mutations.SET_AUTH_ERROR);
      localStorage.removeItem('token');
    }
  }
};

const generateSyncOrgFetch = ({ refreshStripe }: { refreshStripe: boolean }) =>
  generateFetch<IOrg>({
    getLoadState: (state: IState) => state.syncOrgLoadState,
    setLoadStateMutation: mutations.SET_SYNC_ORG_LOAD_STATE,
    async fetchValue({ state, dispatch }: ActionContext) {
      if (!state.user?.org) return null;
      dispatch(actions.FETCH_ORG_PRODUCTS);
      dispatch(actions.FETCH_ORG_PRICES);
      dispatch(actions.FETCH_ORG_COUPONS);
      return (await OrgService.getPopulated(state.user.org, { refreshStripe })) as IOrg;
    },
    setValueMutation: mutations.SET_ORG,
    actions: {
      beforeFetch: [
        {
          action: actions.SYNC_USER_RECORD,
          if: (state: IState) => state.syncUserLoadState !== LOAD_STATE.LOADED,
        },
      ],
    },
  });

const syncOrg = generateSyncOrgFetch({ refreshStripe: false });

const syncOrgRefreshStripe = generateSyncOrgFetch({ refreshStripe: true });

const syncAnalytics = async ({ getters, state }: IActionContext) => {
  if (state.user) {
    // window.heap.identify(state.user.email);
    if (state.org) {
      const stripeCustomer = getters.orgCustomer;
      if (stripeCustomer?.customerPrice) {
        // window.heap.addUserProperties({
        //   orgName: state.org.name,
        //   orgId: state.org._id,
        //   paymentProvider: state.org.paymentProvider,
        //   blueprintCount: state.org.blueprints.length,
        //   plan: stripeCustomer.customerPrice.id,
        //   price: stripeCustomer.customerPrice.amount / 100,
        // });
      } else {
        // window.heap.addUserProperties({
        //   orgName: state.org.name,
        //   orgId: state.org._id,
        //   blueprintCount: state.org.blueprints.length,
        //   paymentProvider: state.org.paymentProvider,
        // });
      }
    }
  }
};

const syncUser = generateFetch({
  getLoadState: (state: IState) => state.syncUserLoadState,
  setLoadStateMutation: mutations.SET_SYNC_USER_LOAD_STATE,
  async fetchValue() {
    return AuthService.getMe();
  },
  setValueMutation: mutations.SET_USER,
  actions: {
    afterLoaded: [[actions.SYNC_ORG, actions.SYNC_ANALYTICS, actions.WEBHOOK_FETCH_ALL, actions.FETCH_ORG_COUPONS, actions.FETCH_PERMISSIONS]],
  },
  events: {
    afterLoaded: ['updateIntercom', 'ckActive', 'userbackInitialize'],
  },
});

const switchOrg = async ({ dispatch, commit }: IActionContext, newOrgId: string) => {
  commit(mutations.SET_AS_ORG, newOrgId);
  await dispatch(actions.SYNC_USER_RECORD);
  await dispatch(actions.SYNC_ORG);
};

const clearAuthState = async ({ commit }: IActionContext) => {
  commit(mutations.SET_LOGOUT);
  commit(mutations.CLEAR_STATE);
  localStorage.removeItem('token');
  delete axios.defaults.headers.common.Authorization;
  EventBus.$emit('destroyIntercom');
};

const logout = async ({ commit, dispatch }: IActionContext) => {
  try {
    commit(mutations.SET_AUTH_LOADING);
    await axios.post('/auth/logout');
  } catch (error) {
    console.error('Logout failed', error);
  } finally {
    await dispatch(actions.CLEAR_AUTH_STATE);
    if (router.currentRoute.path !== '/login') {
      router.push('/login');
    }
  }
};
const orgNotificationsUpdate = async ({ state, getters }: IActionContext) => {
  try {
    if (!getters.hasPermission('cf:write')) return;
    if (state.org && state.org.notifications && state.org.notifications.slack) {
      await OrgService.updateNotifications(state.org._id, state.org.notifications);
      return state.org.notifications;
    }
    return null;
  } catch (e) {
    console.error('Failed to update organization notifications:', e);
    return null;
  }
};

const removeSlackConnection = async ({ state, commit }: IActionContext) => {
  if (state.org?.slack) {
    await OrgService.removeSlackConnection(state.org._id);
    commit(mutations.SET_ORG_DETAILS, { slack: null });
    return true;
  }
  return false;
};

const fetchWebhooks = async ({ state, commit, dispatch }: IActionContext) => {
  try {
    await dispatch(actions.SYNC_ORG);
    const webhooks = await WebhookService.getAll();
    webhooks.forEach((webhook) => {
      commit(mutations.STORE_WEBHOOK_DATA, { id: webhook._id, data: { ...webhook, saveState: SAVE_STATE.SAVED, editable: false } });
    });
    return webhooks;
  } catch (e) {
    console.error('Error in fetchWebhooks:', e);
    return null;
  }
};

const webhookUpdate = async ({ state, commit }: IActionContext, { id }: { id: string }) => {
  try {
    if (state.webhooks[id]) {
      commit(mutations.STORE_WEBHOOK_DATA, { id, data: { saveState: SAVE_STATE.SAVING } });
      await WebhookService.update(id, state.webhooks[id]);
      commit(mutations.STORE_WEBHOOK_DATA, { id, data: { saveState: SAVE_STATE.SAVED } });
    }
    return state.webhooks[id];
  } catch (e) {
    commit(mutations.STORE_WEBHOOK_DATA, { id, data: { saveState: SAVE_STATE.ERROR_SAVING } });
    console.error('Error in webhookUpdate:', e);
    return null;
  }
};

const webhookCreate = async ({ commit }: IActionContext, payload: any) => {
  try {
    const webhook = await WebhookService.create(payload);
    // store webhooks in store like blueprints
    commit(mutations.STORE_WEBHOOK_DATA, { id: webhook._id, data: { ...webhook, saveState: SAVE_STATE.SAVED } });
    return webhook;
  } catch (error) {
    console.error('Error creating webhook:', error);
    return null;
  }
};

const orgBrandingUpdate = async ({ state, getters }: IActionContext) => {
  try {
    if (!getters.hasPermission('cf:write')) return;
    if (state.org && state.org.branding) {
      await OrgService.updateBranding(state.org._id, state.org.branding);
      return state.org.branding;
    }
    return null;
  } catch (e) {
    console.error('Failed to update organization branding:', e);
    return null;
  }
};

const createBlueprint = async ({ commit }: IActionContext, payload: any) => {
  try {
    const blueprint = await BlueprintService.create(payload);
    commit(mutations.STORE_BLUEPRINT_DATA_FROM_RAW, { id: blueprint._id, raw: blueprint });
    return blueprint;
  } catch (error) {
    console.error('Error creating blueprint:', error);
    return null;
  }
};

const blueprintGet = async ({ commit }: IActionContext, id: string) => {
  commit(mutations.STORE_BLUEPRINT_DATA, { id, data: { loadState: LOAD_STATE.LOADING } });
  try {
    const raw = await BlueprintService.get(id);
    commit(mutations.STORE_BLUEPRINT_DATA_FROM_RAW, { id, raw });
  } catch (e) {
    console.error('Error in blueprintGet:', e);
    commit(mutations.STORE_BLUEPRINT_DATA, { id, data: { loadState: LOAD_STATE.ERROR_LOADING } });
  }
};

const segmentGet = async ({ commit }: IActionContext, id: string) => {
  commit(mutations.STORE_SEGMENT_DATA, { id, data: { loadState: LOAD_STATE.LOADING } });
  try {
    const data = await SegmentService.get(id);
    commit(mutations.STORE_SEGMENT_DATA, { id, data: { ...data, loadState: LOAD_STATE.LOADED } });
  } catch (e) {
    console.error('Error in segmentGet:', e);
    commit(mutations.STORE_SEGMENT_DATA, { id, data: { loadState: LOAD_STATE.ERROR_LOADING } });
  }
};

const segmentUpdate = async ({ state, commit }: IActionContext, { id }: { id: string }) => {
  try {
    commit(mutations.STORE_SEGMENT_DATA, { id, data: { saveState: SAVE_STATE.SAVING } });
    const updatedSegment = await SegmentService.update(id, state.segments[id]);
    // change to saved if it hasn't been marked as unsaved while saving
    if (state.segments[id].saveState === SAVE_STATE.SAVING) {
      commit(mutations.STORE_SEGMENT_DATA, {
        id,
        data: { saveState: SAVE_STATE.SAVED },
      });
    }
  } catch (e) {
    console.error('Error in segmentUpdate:', e);
    commit(mutations.STORE_SEGMENT_DATA, { id, data: { saveState: SAVE_STATE.ERROR_SAVING } });
  }
};

const segmentClone = async ({ state, commit, dispatch }: IActionContext, { id }: { id: string }) => {
  try {
    const segment = await SegmentService.segmentClone(id);
    await dispatch(actions.SYNC_USER_RECORD);
    return segment;
  } catch (error) {
    console.error('Error cloning segment:', error);
    return null;
  }
};

const createABTest = async ({ state, commit, dispatch }: IActionContext, { id, data }: { id: string; data: string }) => {
  try {
    const segment = await SegmentService.createABTest(id, data);
    await dispatch(actions.SYNC_USER_RECORD);
    return segment;
  } catch (error) {
    console.error('Error creating AB test:', error);
    return null;
  }
};

const updateSegmentsOrder = async ({ state, getters }: IActionContext, { segments }: { segments: any }) => {
  try {
    if (!getters.hasPermission('cf:write')) return;
    const orgId = getters.org._id;
    const response = await OrgService.reorder(orgId, { segments });
    return true;
  } catch (error) {
    console.error('Error in updateSegmentsOrder:', error);
    return null;
  }
};

const updateABTest = async ({ state, commit, dispatch }: IActionContext, { id, reload, data }: { id: string; reload: boolean; data: any }) => {
  try {
    const abTest = await SegmentService.updateABTest(id, data);
    if (reload) {
      await dispatch(actions.SYNC_USER_RECORD);
    } else {
      commit(mutations.STORE_ABTEST_DATA, {
        id,
        data: { ...abTest },
      });
    }
    return abTest;
  } catch (e) {
    console.error('Error in updateABTest:', e);
    return null;
  }
};

const blueprintUpdate = async ({ state, commit, getters }: IActionContext, { id }: { id: string }) => {
  try {
    commit(mutations.STORE_BLUEPRINT_DATA, { id, data: { saveState: SAVE_STATE.SAVING } });
    const updatedBlueprint = await BlueprintService.update(id, state.blueprints[id]);
    // change to saved if it hasn't been marked as unsaved while saving
    if (state.blueprints[id].saveState === SAVE_STATE.SAVING) {
      commit(mutations.STORE_BLUEPRINT_DATA, {
        id,
        data: { saveState: SAVE_STATE.SAVED, updatedAt: updatedBlueprint.updatedAt },
      });
    }
  } catch (e) {
    console.error('Error in blueprintUpdate:', e);
    commit(mutations.STORE_BLUEPRINT_DATA, { id, data: { saveState: SAVE_STATE.ERROR_SAVING } });
  }
};

const blueprintPublish = async ({ state, commit, dispatch }: IActionContext, { id }: { id: string }) => {
  try {
    // freeze operations during publish
    const blueprint = state.blueprints[id];
    // update if unsaved
    if (blueprint.saveState === SAVE_STATE.UNSAVED) {
      if (blueprint.batchUpdateTimeout) clearTimeout(blueprint.batchUpdateTimeout);
      await dispatch(actions.BLUEPRINT_UPDATE, { id });
    }
    const { published, updated } = await BlueprintService.publish(id);
    await dispatch(actions.BLUEPRINT_GET, published._id);
    await dispatch(actions.BLUEPRINT_GET, updated._id);
    const org = await OrgService.get(state.user!.org);
    commit(mutations.SET_ORG, org);
    // await dispatch(actions.SYNC_USER_RECORD);
  } catch (e) {
    console.error('Error in blueprintPublish:', e);
    commit(mutations.STORE_BLUEPRINT_DATA, { id, data: { saveState: SAVE_STATE.ERROR_SAVING } });
  }
};

const blueprintSegmentPublish = async ({ state, commit, dispatch }: IActionContext, { id, segmentId }: { id: string; segmentId: string }) => {
  try {
    // freeze operations during publish
    const blueprint = state.blueprints[id];
    const segment = state.segments[segmentId];
    // update if unsaved
    if (blueprint.saveState === SAVE_STATE.UNSAVED) {
      if (blueprint.batchUpdateTimeout) clearTimeout(blueprint.batchUpdateTimeout);
      await dispatch(actions.BLUEPRINT_UPDATE, { id });
    }
    if (segment.saveState === SAVE_STATE.UNSAVED) {
      if (blueprint.batchUpdateTimeout) clearTimeout(blueprint.batchUpdateTimeout);
      await dispatch(actions.SEGMENT_UPDATE, { id: segmentId });
    }
    const { published, updated } = await BlueprintService.publishSegment(id, segmentId);
    await dispatch(actions.BLUEPRINT_GET, published._id);
    await dispatch(actions.BLUEPRINT_GET, updated._id);
    await dispatch(actions.SEGMENT_GET, segmentId);
    const org = await OrgService.getPopulated(state.user!.org);
    commit(mutations.SET_ORG, org);
  } catch (e) {
    console.error('Error in blueprintSegmentPublish:', e);
    commit(mutations.STORE_BLUEPRINT_DATA, { id, data: { saveState: SAVE_STATE.ERROR_SAVING } });
    commit(mutations.STORE_SEGMENT_DATA, { id, data: { saveState: SAVE_STATE.ERROR_SAVING } });
  }
};

const blueprintScheduleSave = async ({ state, commit, dispatch, getters }: IActionContext, { id, delay }: { id: string; delay?: number }) => {
  const batchUpdateDelay = delay !== undefined ? delay : 2000;
  commit(mutations.STORE_BLUEPRINT_DATA, { id, data: { saveState: SAVE_STATE.UNSAVED } });
  if (!getters.hasPermission('cf:write')) {
    return;
  }
  const existingBatchUpdateTimeout = state.blueprints[id].batchUpdateTimeout;
  if (existingBatchUpdateTimeout) {
    clearTimeout(existingBatchUpdateTimeout);
  }
  const batchUpdate = () => {
    dispatch(actions.BLUEPRINT_UPDATE, { id });
  };
  const batchUpdateTimeout = setTimeout(batchUpdate, batchUpdateDelay);
  commit(mutations.STORE_BLUEPRINT_DATA, { id, data: { batchUpdateTimeout } });
};

const segmentScheduleSave = async ({ state, commit, dispatch, getters }: IActionContext, { id, delay }: { id: string; delay?: number }) => {
  const batchUpdateDelay = delay !== undefined ? delay : 2000;
  commit(mutations.STORE_SEGMENT_DATA, { id, data: { saveState: SAVE_STATE.UNSAVED } });
  if (!getters.hasPermission('cf:write')) {
    return;
  }
  const existingBatchUpdateTimeout = state.segments[id].batchUpdateTimeout;
  if (existingBatchUpdateTimeout) {
    clearTimeout(existingBatchUpdateTimeout);
  }
  const batchUpdate = () => {
    dispatch(actions.SEGMENT_UPDATE, { id });
  };
  const batchUpdateTimeout = setTimeout(batchUpdate, batchUpdateDelay);
  commit(mutations.STORE_SEGMENT_DATA, { id, data: { batchUpdateTimeout } });
};

const planSettingsScheduleSave = async ({ state, commit, dispatch, getters }: IActionContext, { delay }: { delay?: number } = {}) => {
  const batchUpdateDelay = delay !== undefined ? delay : 2000;
  commit(mutations.SET_ORG_DETAILS, { saveState: SAVE_STATE.UNSAVED });
  if (!getters.hasPermission('cf:write')) {
    return;
  }
  const existingBatchUpdateTimeout = state?.org?.batchUpdateTimeout;
  if (existingBatchUpdateTimeout) {
    clearTimeout(existingBatchUpdateTimeout);
  }
  // mirrors segmentUpdate
  const batchUpdate = async () => {
    try {
      if (state.org) {
        // set as saving
        commit(mutations.SET_ORG_DETAILS, { saveState: SAVE_STATE.SAVING });
        // push to database
        await OrgService.update(state.org._id, { plans: state.org.plans });
        // change to saved if it hasn't been marked as unsaved while saving
        if (state.org.saveState === SAVE_STATE.SAVING) {
          commit(mutations.SET_ORG_DETAILS, { saveState: SAVE_STATE.SAVED });
        }
      }
    } catch (e) {
      console.error('Error in planSettingsScheduleSave:', e);
      commit(mutations.SET_ORG_DETAILS, { saveState: SAVE_STATE.ERROR_SAVING });
    }
  };
  const batchUpdateTimeout = setTimeout(batchUpdate, batchUpdateDelay);
  commit(mutations.SET_ORG_DETAILS, { batchUpdateTimeout });
};

type BillingItem = 'products' | 'prices' | 'coupons';
const fetchOrgBillingItems = async (
  { state, commit, getters }: IActionContext,
  fetchItem: Function,
  itemKey: BillingItem,
  mutationKeys: any,
  forceReload?: boolean
) => {
  try {
    if (!(state.org && state.org.secrets)) {
      return;
    }
    if (Object.keys(state.org?.secrets?.prod || {}).length === 0 && !state.org?.secrets?.stripeAccountId && !state.org?.stripeAccountId) {
      return;
    }

    const usingStripe = getters.confirmedPaymentProvider === 'stripe';
    const usingBraintree = getters.confirmedPaymentProvider === 'braintree';
    const usingChargebee = getters.confirmedPaymentProvider === 'chargebee';
    const usingPaddleBilling = getters.isPaddleBilling;
    const usingPaddleClassic = getters.isPaddleClassic;

    const hasStripeCreds = state.org.stripeAccountId;
    const hasBraintreeCreds =
      state.org.secrets?.prod &&
      !!state.org.secrets.prod.braintreeMerchantId &&
      !!state.org.secrets.prod.braintreePublicKey &&
      !!state.org.secrets.prod.braintreePrivateKey;
    const hasChargebeeCreds = !!state.org.secrets.prod.chargebeeSite && !!state.org.secrets.prod.chargebeeApiKey;
    const hasPaddleCreds = !!state.org.secrets.prod.paddleVendorId && !!state.org.secrets.prod.paddleAuthCode;
    const hasPaddleBillingCreds = !!state.org.secrets.prod.paddleSecret && !!state.org.secrets.prod.paddleWebhookSecret;

    if (!usingStripe && !usingBraintree && !usingChargebee && !usingPaddleClassic && !usingPaddleBilling) {
      return;
    }
    if (usingPaddleClassic && !hasPaddleCreds) {
      return;
    }
    if (usingPaddleBilling && !hasPaddleBillingCreds) {
      return;
    }
    if (usingChargebee && !hasChargebeeCreds) {
      return;
    }
    if (usingBraintree && !hasBraintreeCreds) {
      return;
    }
    if (usingStripe && !hasStripeCreds) {
      return;
    }

    if (getters.isLiteSubscriber) {
      return;
    }

    if (itemKey === 'products' && !usingStripe) {
      return;
    }

    const lackItem = !state.stripeData[itemKey];
    if (state.user && state.user.org && (lackItem || forceReload)) {
      commit(mutationKeys.load, LOAD_STATE.LOADING);
      const items = await fetchItem(state.asOrg || state.user.org);
      commit(mutationKeys.set, items);
      commit(mutationKeys.loaded, LOAD_STATE.LOADED);
    }
  } catch (err) {
    console.error(`Failed to fetch ${itemKey}:`, err);
    commit(mutationKeys.error, LOAD_STATE.ERROR_LOADING);
  }
};

const fetchOrgCoupons = async (context: IActionContext, forceReload?: boolean) => {
  fetchOrgBillingItems(
    context,
    OrgService.getCoupons,
    'coupons',
    {
      load: mutations.SET_STRIPE_COUPONS_LOAD_STATE,
      set: mutations.SET_STRIPE_COUPONS,
      loaded: mutations.SET_STRIPE_COUPONS_LOAD_STATE,
      error: mutations.SET_STRIPE_COUPONS_LOAD_STATE,
    },
    forceReload
  );
};

const fetchOrgPrices = async (context: IActionContext, forceReload?: boolean) => {
  fetchOrgBillingItems(
    context,
    OrgService.getPrices,
    'prices',
    {
      load: mutations.SET_STRIPE_PRICES_LOAD_STATE,
      set: mutations.SET_STRIPE_PRICES,
      loaded: mutations.SET_STRIPE_PRICES_LOAD_STATE,
      error: mutations.SET_STRIPE_PRICES_LOAD_STATE,
    },
    forceReload
  );
};

const fetchOrgProducts = async (context: IActionContext, forceReload?: boolean) => {
  fetchOrgBillingItems(
    context,
    OrgService.getProducts,
    'products',
    {
      load: mutations.SET_STRIPE_PRODUCTS_LOAD_STATE,
      set: mutations.SET_STRIPE_PRODUCTS,
      loaded: mutations.SET_STRIPE_PRODUCTS_LOAD_STATE,
      error: mutations.SET_STRIPE_PRODUCTS_LOAD_STATE,
    },
    forceReload
  );
};

const segmentCreate = async ({ commit }: IActionContext, payload: any) => {
  try {
    const segment = await SegmentService.create(payload);
    // store segments in store like blueprints
    commit(mutations.STORE_SEGMENT_DATA, { id: segment._id, data: segment });
    return segment;
  } catch (error) {
    console.error('Error creating blueprint', error);
    return null;
  }
};

const verifyMagicLinkAuthToken = async ({ commit, dispatch, state }: IActionContext, token: string): Promise<void> => {
  commit(mutations.SET_AUTH_LOADING);

  try {
    const response = await AuthService.verifyMagicLinkToken(token);

    if (!response.token) {
      throw new Error('No authentication token received');
    }

    // Set up auth state
    localStorage.setItem('token', response.token);
    axios.defaults.headers.common.Authorization = `Bearer ${response.token}`;
    commit(mutations.SET_AUTH_SUCCESS, response.token);

    await dispatch(actions.SYNC_USER_RECORD, { force: true });

    if (state.user && state.user._id) {
      await UserService.updateSelf({
        emailConfirmed: true,
      });
      commit(mutations.SET_USER_DATA, { emailConfirmed: true });
    }
    router.push('/account');
  } catch (error) {
    // Clear auth state on failure
    dispatch(actions.CLEAR_AUTH_STATE);
    commit(mutations.SET_AUTH_ERROR);
    throw error;
  }
};

const _setupWidget = () => {
  const widget = document.createElement('ck-widget');
  window.document.body.appendChild(widget);
};

const launchEmbedPreview = async (
  { state, getters, dispatch }: IActionContext,
  { blueprintId, language }: { blueprintId?: string; language?: string } = {}
) => {
  const unixSeconds: number = Math.floor(new Date().getTime() / 1000);
  const id = blueprintId || state.currentBlueprintId;
  const existingWidgets = window.document.getElementsByTagName('ck-widget');
  if (!existingWidgets.length) {
    _setupWidget();
  }
  if (state.org && id) {
    window.ckBlueprint = { ...state.blueprints[id], ...state.org.branding }; // TODO: Clean up branding later

    window.ckBranding = state.org.branding;

    window.ckCoupons = state.stripeData.coupons;

    window.ckOrgPlanSettings = state.org.plans;

    window.ckOfferPlans = state.stripeData.prices;
    //@ts-ignore
    window.ckOrgTranslations = state.org.translations;
    if (getters.providerWithVersion.toLowerCase() === 'paddle') {
      const couponIds = findBlueprintCouponIds(window.ckBlueprint);

      const coupons = mockPaddleCoupons(couponIds);

      window.ckCoupons = coupons;
    }

    const sampleCustomer = {
      email: 'preview@churnkey.co',
      created: Math.floor(unixSeconds - 60 * 60 * 24 * 20),
      subscriptions: {
        data: [
          {
            current_period_end: new Date().setDate(new Date().getDate() + 30) / 1000,
            cancel_at_period_end: false,
            status: 'active',
            items: {
              data: [
                {
                  status: 'active',
                  cancel_at_period_end: false,
                  price: {
                    id: 'sample_price',
                    currency: 'usd',
                  },
                },
              ],
            },
          },
        ],
      },
    };

    sampleCustomer.decorated = new StripeCustomer(sampleCustomer);

    window.ckCustomer = sampleCustomer;
    if (getters.isLiteSubscriber) {
      window.churnkey.init('insight', {
        report: false,
        preview: true,
        provider: getters.providerWithVersion || 'stripe',
        onCancel() {
          console.log('Account canceled');
        },
      });
    } else {
      const subscriptionStart = new Date();
      subscriptionStart.setDate(subscriptionStart.getDate() - 90);

      window.churnkey.init('restart', {
        report: false,
        preview: true,
        inline: getters.previewInline,
        target: '#inline-preview',
        provider: (getters.providerWithVersion || 'stripe').toLowerCase(),
        i18n: {
          lang: language || 'en',
        },
        customerAttributes: {
          CUSTOMER_NAME: 'Jane Doe',
          SUBSCRIPTION_AGE: '3 months',
          SUBSCRIPTION_STATUS: 'Active',
          SUBSCRIPTION_START_DATE: subscriptionStart.toLocaleDateString(),
          'CUSTOMER_NAME.FIRST': 'Jane',
          'CUSTOMER_NAME.LAST': 'Doe',
          SUBSCRIPTION_PRICE: '$45 / month',
        },
        handleSupportRequest() {
          console.log('Triggered support request');

          window.churnkey.hide();
        },
        onClose() {
          const inlineElem = document.getElementById('inline-preview');
          if (getters.previewInline === false && inlineElem) {
            setTimeout(() => {
              dispatch(actions.LAUNCH_INLINE_PREVIEW, { blueprintId: id });
            }, 10);
          } else if (inlineElem) {
            // ALSO if closing within inline mode, let's reset the inline preview
            setTimeout(() => {
              dispatch(actions.LAUNCH_INLINE_PREVIEW, { blueprintId: id });
            }, 10);
          }
        },
      });
    }
  }
};

const launchInlinePreview = async (
  { getters, commit, dispatch }: IActionContext,
  { blueprintId, language }: { blueprintId?: string; language?: string } = {}
) => {
  commit(mutations.SET_PREVIEW_INLINE, true);
  dispatch(actions.LAUNCH_EMBED_PREVIEW, { blueprintId, language });
};

const getIssues = async ({ commit, state }: IActionContext) => {
  try {
    if (state.user && state.user.org) {
      const issues = await OrgService.getIssues(state.user.org);
      commit(mutations.SET_ISSUES, issues);
    }
  } catch (err) {
    console.error('Error in getIssues:', err);
  }
};

const updateIssue = async ({ commit, state }: IActionContext, issueUpdate: IssueUpdate) => {
  try {
    if (state.user && state.user.org) {
      await OrgService.updateIssue(state.user.org, issueUpdate);
    }
    const issueIndex = state.issues.findIndex((issue) => issue._id === issueUpdate._id);
    if (issueIndex !== -1) {
      state.issues[issueIndex] = {
        ...state.issues[issueIndex],
        ...issueUpdate,
      };
      state.issues = [...state.issues];
    }
  } catch (err) {
    console.error('Error in updateIssue:', err);
  }
};

const adminSetOrgSetting = async (
  { commit, state }: IActionContext,
  { orgId, setting, value }: { orgId: string; setting: string; value: boolean }
) => {
  const settingsPaths = {
    reactivation: 'reactivation.enabled',
    smsEnabled: 'dunning.smsEnabled',
    dunning: 'dunning.enabled',
    precisionRetries: 'smartRetries.enabled',
    showWatermark: 'branding.showWatermark',
    stripeCustomerId: 'stripeCustomerId',
    feedbackAIEnabled: 'feedbackAIEnabled',
    churnMetricsEnabled: 'churnMetricsEnabled',
    orgName: 'name',
  };
  try {
    const path = settingsPaths[setting];
    if (!path) {
      throw new Error(`Feature ${setting} not found`);
    }
    await adminService.adminSetOrgSetting(orgId, setting, value);
    commit(mutations.ADMIN_STORE_ORG_DATA, { orgId, path, value });
  } catch (err) {
    console.error('Error setting feature flag:', err);
  }
};

// const newProviderSettings = { ...this.orgProviderSettings, ...update };
// this.$store.commit(SET_ORG_DETAILS, { providerSettings: newProviderSettings });
// if (!this.$store.getters.hasPermission('cf:write')) return;
// await OrgService.update(this.$store.state.org._id, { providerSettings: newProviderSettings });
// },

const optimisticOrgUpdate = async ({ state, getters, commit }: IActionContext, orgUpdate: any) => {
  if (!getters.hasPermission('cf:write')) return;
  if (!state.org) return;
  const fallback = { ...state.org };
  commit(mutations.SET_ORG_DETAILS, { ...orgUpdate });
  try {
    await OrgService.update(state.org._id, orgUpdate);
  } catch (e) {
    EventBus.$emit('alert', 'Error', 'We were unable to save your changes. Please try again.');
    commit(mutations.SET_ORG_DETAILS, fallback);
  }
};

export default {
  [actions.LOGIN]: login,
  [actions.LOGOUT]: logout,
  [actions.REGISTER]: register,
  [actions.CLEAR_AUTH_STATE]: clearAuthState,
  [actions.SYNC_USER_RECORD]: syncUser,
  [actions.SWITCH_ORG]: switchOrg,
  [actions.SYNC_ORG]: syncOrg,
  [actions.SYNC_ORG_REFRESH_STRIPE]: syncOrgRefreshStripe,
  [actions.OPTIMISTIC_ORG_UPDATE]: optimisticOrgUpdate,
  [actions.FETCH_ORG_COUPONS]: fetchOrgCoupons,
  [actions.FETCH_ORG_PRICES]: fetchOrgPrices,
  [actions.FETCH_ORG_PRODUCTS]: fetchOrgProducts,
  [actions.ORG_NOTIFICATIONS_UPDATE]: orgNotificationsUpdate,
  [actions.ORG_BRANDING_UPDATE]: orgBrandingUpdate,
  [actions.CREATE_BLUEPRINT]: createBlueprint,
  [actions.SEGMENT_CREATE]: segmentCreate,
  [actions.SEGMENT_CLONE]: segmentClone,
  [actions.CREATE_AB_TEST]: createABTest,
  [actions.UPDATE_AB_TEST]: updateABTest,
  [actions.UPDATE_SEGMENT_ORDER]: updateSegmentsOrder,
  [actions.SEGMENT_GET]: segmentGet,
  [actions.SEGMENT_UPDATE]: segmentUpdate,
  [actions.SEGMENT_SCHEDULE_SAVE]: segmentScheduleSave,
  [actions.PLAN_SETTINGS_SCHEDULE_SAVE]: planSettingsScheduleSave,
  [actions.WEBHOOK_CREATE]: webhookCreate,
  [actions.WEBHOOK_FETCH_ALL]: fetchWebhooks,
  [actions.WEBHOOK_UPDATE]: webhookUpdate,
  [actions.REMOVE_SLACK_CONNECTION]: removeSlackConnection,
  [actions.BLUEPRINT_GET]: blueprintGet,
  [actions.BLUEPRINT_UPDATE]: blueprintUpdate,
  [actions.BLUEPRINT_SCHEDULE_SAVE]: blueprintScheduleSave,
  [actions.BLUEPRINT_PUBLISH]: blueprintPublish,
  [actions.BLUEPRINT_SEGMENT_PUBLISH]: blueprintSegmentPublish,
  [actions.VERIFY_MAGIC_LINK_AUTH_TOKEN]: verifyMagicLinkAuthToken,
  [actions.LAUNCH_EMBED_PREVIEW]: launchEmbedPreview,
  [actions.LAUNCH_INLINE_PREVIEW]: launchInlinePreview,
  [actions.SYNC_ANALYTICS]: syncAnalytics,
  [actions.GET_ISSUES]: getIssues,
  [actions.UPDATE_ISSUE]: updateIssue,
  [actions.ADMIN_SET_ORG_SETTING]: adminSetOrgSetting,
};
