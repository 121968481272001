import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import EventBus from '@/helpers/event-bus';
import routes from '@/router/routes';
import * as actions from '@/store/action-types';
import * as mutations from '@/store/mutation-types';
import { updateTitle } from '@/router/title-guard';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes,
});

router.afterEach((to) => {
  updateTitle(to);
});

const verifyMagicLinkAuthToken = async (token: string) => {
  try {
    await store.dispatch(actions.VERIFY_MAGIC_LINK_AUTH_TOKEN, token);

    if (store.state?.authStatus === 'error') {
      throw new Error('Link Expired');
    }

    return true;
  } catch (error: any) {
    const isExpiredToken = error.response?.status === 401;
    const message = isExpiredToken ? 'Link Expired' : 'Verification failed';
    const detail = 'Please retry email reset or contact support.';

    EventBus.$emit('alert', message, detail);
    return false;
  }
};

// perform auth check
router.beforeEach(async (to, from, next) => {
  // Router path tracking
  EventBus.$emit('trackView', to.name, to.fullPath);

  // check for audit param and store value
  if (to.query && to.query.q === 'audit') {
    localStorage.setItem('audit', 'true');
  }

  // check for audit param and store value
  if (to.query && to.query.promo) {
    store.commit(mutations.SET_PROMO, to.query.promo);
  }

  // check for dunning vs cancel flow intent
  if (to.query && to.query.for) {
    store.commit(mutations.SET_PRODUCT_INTENT, to.query.for);
  }

  // check for logout
  if (to.path === '/logout') {
    store.dispatch(actions.LOGOUT);
  }

  // Handle magic link auth token verification for email verification and for invite links
  if (to.params?.authToken) {
    const success = await verifyMagicLinkAuthToken(to.params.authToken);
    if (!success) {
      next('/login');
      return;
    }
  }

  // guard for authenticated routes
  if (to.matched.some((r) => r.meta.requiresAuth === true)) {
    if (store.getters.isLoggedIn) {
      if (store.state && store.state.user === null) {
        try {
          await store.dispatch(actions.SYNC_USER_RECORD);
        } catch (err) {
          console.error('Navigation guard error:', err);
        }
      }
      if (store.state && store.state.org === null) {
        try {
          await store.dispatch(actions.SYNC_ORG);
        } catch (err) {
          console.error('Navigation guard error:', err);
        }
      }
      if (to.matched.some((r) => r.meta.requiresAdmin === true)) {
        if (!store.getters.isAdmin) {
          next('/');
          return;
        }
      }
      if (to.matched.some((r) => r.meta.requiresFeature && store.state.org && !store.state.org[r.meta.requiresFeature])) {
        next('/');
        return;
      }
      next();
      return;
    }
    next('/login');
  } else {
    next();
  }
});

export default router;
