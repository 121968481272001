<template>
  <el-dialog :visible.sync="showTask" class="p-0" custom-class="container max-w-2xl rounded-lg px-0 py-0 no-padding" :close-on-click-modal="!blocked">
    <div>
      <div class="px-8 pt-6 pb-6">
        <div>
          <div class="text-xl font-bold text-brand-black">{{ title }}</div>
          <div class="text-brand-brown mt-1.5 text-base font-medium">
            {{ description }}
          </div>
        </div>
        <div class="py-6">
          <div v-if="taskItems" class="space-y-8">
            <div v-for="(item, index) in taskItems" :key="item.ref">
              <h3 class="font-bold text-gray-700">Step {{ index + 1 }}: {{ item.title }}</h3>
              <p class="text-brand-brown text-sm font-medium mt-0.5">{{ item.description }}</p>
              <div v-if="item.status === 'RUNNING' && item.percentage == undefined" class="w-full mt-2.5 pr-12">
                <div class="h-2 w-full bg-gray-100 overflow-hidden rounded-md">
                  <div class="animate-progress w-full h-full bg-brand-blue origin-left-right"></div>
                </div>
              </div>
              <el-progress :stroke-width="8" v-else-if="item.status === 'PENDING'" class="w-full mt-2" :percentage="0"></el-progress>
              <el-progress
                :stroke-width="8"
                v-else-if="item.status === 'RUNNING' && item.percentage != undefined"
                class="w-full mt-2"
                :percentage="item.percentage"
              ></el-progress>
              <el-progress :stroke-width="8" v-else-if="item.status === 'COMPLETED'" class="w-full mt-2" :percentage="100" status="success">
              </el-progress>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-brand-light-sand border-t-2 border-brand-sand flex justify-end px-8 py-6 rounded-b-lg">
        <el-button :disabled="blocked" @click="close" type="plain" class="border-brand-gray-blue border-2 px-6 py-2 rounded-lg">
          <span class="text-sm text-brand-blue font-semibold">Close</span>
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import TaskService from '@/api/task.service';
import { ITask, TaskStatus } from '@/store/types';
import eventBus from '@/helpers/event-bus';

export default Vue.extend({
  name: 'TaskMonitor',
  props: {
    taskId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showTask: false,
      task: null as ITask | null,
      loading: false,
      error: null as any,
      interval: null as any,
    };
  },
  async created() {
    await this.fetchTaskProgress();
    this.loading = false;
  },
  computed: {
    percentage(): number {
      return this.task?.percentage || 0;
    },
    taskItems(): any[] {
      return this.task?.items || [];
    },
    title(): string {
      return this.task?.title || 'Processing Task';
    },
    description(): string {
      return this.task?.description || 'Please wait while this task is being processed';
    },
    blocked(): boolean {
      return (this.task?.blocking && this.task?.status === TaskStatus.RUNNING) || false;
    },
  },
  methods: {
    async fetchTaskProgress() {
      try {
        const response = await TaskService.get(this.taskId);
        if (response && response.status !== TaskStatus.COMPLETED) {
          this.showTask = true;
        } else {
          eventBus.$emit('task-completed', this.taskId, this.task?.taskType);
          // this.showTask = false;
          this.stopPolling();
        }
        this.task = response;
      } catch (error) {
        console.error('Failed to fetch task progress:', error);
        this.error = error;
        this.stopPolling();
      }
    },
    startPolling() {
      this.interval = setInterval(this.fetchTaskProgress, 4000);
    },
    stopPolling() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    },
    close() {
      this.showTask = false;
      this.stopPolling();
      this.$emit('close');
    },
  },
  mounted() {
    this.startPolling();
  },
  beforeDestroy() {
    this.stopPolling();
  },
});
</script>

<style scoped>
/* Add any additional styles here */
</style>
