import mixpanel from 'mixpanel-browser';
import store from '@/store';
import { IUser } from '@/store/types';

class AnalyticsService {
  private initialized = false;
  private shouldSkipAnalytics(): boolean {
    return store.getters.isAdmin;
  }

  initialize(): void {
    if (this.shouldSkipAnalytics()) return;
    mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
      debug: import.meta.env.DEV,
      track_pageview: true,
      persistence: 'cookie',
      ignore_dnt: true,
      cross_site_cookie: true,
      cross_subdomain_cookie: true,
    });
    this.initialized = true;
  }

  setUser(user: IUser): void {
    if (this.shouldSkipAnalytics() || !this.initialized) return;

    mixpanel.identify(user._id);
    mixpanel.people.set({
      $distinct_id: user._id,
      $name: user.name,
      $email: user.email,
      role: user.role,
      orgId: user.org,
      emailConfirmed: user.emailConfirmed,
    });
  }

  trackEvent(eventName: string, properties: Record<string, any> = {}): void {
    if (this.shouldSkipAnalytics() || !this.initialized) return;

    mixpanel.track(eventName, properties);
  }
}

export const analytics = new AnalyticsService();
