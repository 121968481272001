import DunningService from '@/api/dunning.service';
import { timeDay, ascending } from 'd3';
import { generateDunningSequence, generateDefaultSms } from '@/helpers/templates';
import OrgService from '@/api/org.service';
import StatsService from '@/api/stats.service';
import { LOAD_STATE, SAVE_STATE } from '../shared';
import * as MUTATE from '../mutation-types';
import * as ACT from '../action-types';
import {
  EmailMergeField,
  ICampaignBlueprint,
  IEmailBlueprint,
  ISmsBlueprint,
  IDunningMessageGroup,
  IEmailCount,
  IExclusion,
  IState,
  IUser,
  LoadState,
} from '../types';
import { customDomainIsValid } from '@/helpers/domain-utils';
import { ProductName } from '@/constants/ProductName';
import { hasNonTrialProductSubscription, hasProductSubscription, hasTrialSubscription } from '@/helpers/subscriptions';
import { getProductByName } from '@/helpers/products';

interface IActionContext {
  commit: any;
  dispatch: any;
  state: IState;
  getters: any;
}

const getUnifiedMessages = (emails: IEmailBlueprint[], sms: ISmsBlueprint[]): IDunningMessageGroup[] => {
  const smsMap: Map<string, ISmsBlueprint> = new Map(sms.map((s: ISmsBlueprint) => [s.guid, s]));
  const joinSaveStates = (email?: IEmailBlueprint, sms?: ISmsBlueprint) => {
    const states = [email?.saveState, sms?.saveState];
    if (states.includes(SAVE_STATE.SAVING)) return SAVE_STATE.SAVING;
    if (states.includes(SAVE_STATE.ERROR_SAVING)) return SAVE_STATE.ERROR_SAVING;
    if (states.includes(SAVE_STATE.UNSAVED)) return SAVE_STATE.UNSAVED;
    if (states.every((state) => state === SAVE_STATE.SAVED)) return SAVE_STATE.SAVED;
    return SAVE_STATE.UNSAVED;
  };

  const messages: IDunningMessageGroup[] = emails.map((email) => {
    const sms = smsMap.get(email.guid) || generateDefaultSms(email);
    const messageGroup: IDunningMessageGroup = {
      guid: email.guid,
      email,
      sms,
      enableEmail: email.enabled,
      enableSms: sms?.enabled,
      delete: email.delete, // deletions happen in both (sms and email) at the same time
      saveState: joinSaveStates(email, sms),
    };
    return messageGroup;
  });

  // This should be unnecessary. We always have an email and sms for each guid. One may be disabled.
  smsMap.forEach((sms, guid) => {
    if (!messages.some((message) => message.guid === guid)) {
      console.error('Warning: No email found for sms', sms); // Add default email?
    }
  });

  return messages;
};

const storeCampaignData = (state: IState, { id, data }: { id: string; data: Partial<ICampaignBlueprint> }) => {
  const campaignData = {
    ...state.campaignBlueprints[id],
    ...data,
  };
  state.campaignBlueprints = {
    ...state.campaignBlueprints,
    [id]: campaignData,
  };
};

const dunningModule = {
  // dunning object (with dkim etc) lives under org
  state: {
    currentCampaignBlueprintId: null,
    currentEmailBlueprintId: null,
    dunningCampaignCounts: {},
    dunningCampaignCountsLoadState: LOAD_STATE.UNLOADED,
  },
  mutations: {
    [MUTATE.STORE_DUNNING_CAMPAIGN_BLUEPRINT_DATA](state: IState, { id, data }: { id: string; data: any }) {
      const campaignBlueprintData = {
        ...state.campaignBlueprints[id],
        ...data,
      };
      state.campaignBlueprints = {
        ...state.campaignBlueprints,
        [id]: campaignBlueprintData,
      };
    },
    [MUTATE.STORE_DUNNING_CAMPAIGN_BLUEPRINT_EMAIL_COUNT](state: IState, { id, data }: { id: string; data: IEmailCount }) {
      const emailCount = {
        ...state.campaignBlueprintEmailCounts[id],
        ...data,
      };
      state.campaignBlueprintEmailCounts = {
        ...state.campaignBlueprintEmailCounts,
        [id]: emailCount,
      };
    },
    [MUTATE.SET_CURRENT_DUNNING_CAMPAIGN_BLUEPRINT_ID](state: IState, blueprintId: string) {
      state.currentCampaignBlueprintId = blueprintId;
    },
    [MUTATE.SET_CURRENT_DUNNING_EMAIL_BLUEPRINT_ID](state: IState, blueprintId: string) {
      state.currentEmailBlueprintId = blueprintId;
    },
    [MUTATE.STORE_DUNNING_SETTINGS](state: IState, payload: any) {
      if (state.org?.dunning) {
        state.org.dunning = { ...state.org.dunning, ...payload };
      }
    },
    // does not override entire email
    [MUTATE.STORE_DUNNING_EMAIL_DATA](
      state: IState,
      { campaignId, messageGuid, data }: { campaignId: string; messageGuid: string; data: Partial<IEmailBlueprint> }
    ) {
      const campaign = state.campaignBlueprints[campaignId];
      if (campaign) {
        const email = campaign.emails.find((e) => e.guid === messageGuid);
        if (email) {
          const newEmail = {
            ...email,
            ...data,
          };
          const newEmails = campaign.emails.map((e) => {
            if (e.guid === messageGuid) {
              return newEmail;
            }
            return e;
          });
          storeCampaignData(state, { id: campaignId, data: { emails: newEmails } });
        } else {
          console.error('No email found, cannot apply update:', data);
        }
      }
    },
    [MUTATE.STORE_DUNNING_SMS_DATA](
      state: IState,
      { campaignId, messageGuid, data }: { campaignId: string; messageGuid: string; data: ISmsBlueprint }
    ) {
      const campaign = state.campaignBlueprints[campaignId];
      if (campaign) {
        if (!campaign.sms) {
          campaign.sms = [];
        }
        // If no sms are present, pre-populate with default sms. As many as emails.
        // And schedule a save.
        if (campaign.sms.length === 0) {
          const sms = campaign.emails.map((e) => generateDefaultSms(e));
          storeCampaignData(state, { id: campaignId, data: { sms } });
        }
        const sms = campaign.sms.find((s) => s.guid === messageGuid);
        if (sms) {
          const newSms = {
            ...sms,
            ...data,
          };
          const newMessages = campaign.sms.map((s) => {
            if (s.guid === messageGuid) {
              return newSms;
            }
            return s;
          });
          storeCampaignData(state, { id: campaignId, data: { sms: newMessages } });
        } else {
          console.error('No SMS found, cannot apply update:', data);
        }
      }
    },
    // overrides entire email
    [MUTATE.SET_DUNNING_CAMPAIGN_EMAIL](
      state: IState,
      { campaignId, emailId, data }: { campaignId: string; emailId: string; data: IEmailBlueprint }
    ) {
      const campaign = state.campaignBlueprints[campaignId];
      if (campaign) {
        const email = campaign.emails.find((e) => e.guid === emailId);
        if (email) {
          const newEmail = data;
          // store vs set
          // const newEmail = {
          //   ...email,
          //   ...data,
          // };
          const newEmails = campaign.emails.map((e) => {
            if (e.guid === emailId) {
              return newEmail;
            }
            return e;
          });
          storeCampaignData(state, { id: campaignId, data: { emails: newEmails, saveState: SAVE_STATE.UNSAVED } });
        }
      }
    },
    [MUTATE.SET_DUNNING_CAMPAIGN_SMS](state: IState, { campaignId, smsId, data }: { campaignId: string; smsId: string; data: ISmsBlueprint }) {
      const campaign = state.campaignBlueprints[campaignId];
      if (campaign) {
        if (!campaign.sms) {
          campaign.sms = [];
        }
        const sms = campaign.sms.find((s) => s.guid === smsId);
        if (sms) {
          const newSms = data;
          const newMessages = campaign.sms.map((m) => {
            if (m.guid === smsId) {
              return newSms;
            }
            return m;
          });
          storeCampaignData(state, { id: campaignId, data: { sms: newMessages, saveState: SAVE_STATE.UNSAVED } });
        }
      }
    },
    [MUTATE.STORE_DUNNING_EXCLUSION_DATA](state: IState, { id, data }: { id: string; data: any }) {
      const dunningExclusionData = {
        ...state.dunningExclusions[id],
        ...data,
      };
      state.dunningExclusions = {
        ...state.dunningExclusions,
        [id]: dunningExclusionData,
      };
    },
    [MUTATE.STORE_DUNNING_EXCLUSION_BLOCK_COUNT](state: IState, { id, data }: { id: string; data: any }) {
      const dunningExclusionBlockCount = {
        ...state.dunningExclusionBlockCounts[id],
        ...data,
      };
      state.dunningExclusionBlockCounts = {
        ...state.dunningExclusionBlockCounts,
        [id]: dunningExclusionBlockCount,
      };
    },
    [MUTATE.SET_DUNNING_CAMPAIGNS_COUNT](state: IState, counts: Record<string, number>) {
      state.dunningCampaignCounts = counts;
    },
    [MUTATE.SET_DUNNING_CAMPAIGNS_COUNT_LOAD_STATE](state: IState, loadState: LoadState) {
      state.dunningCampaignCountsLoadState = loadState;
    },
  },

  actions: {
    // sync dunning info from org and campaign blueprints
    async [ACT.SYNC_DUNNING]({ dispatch }: IActionContext) {
      await dispatch(ACT.SYNC_ORG);
      await Promise.all([dispatch(ACT.SYNC_DUNNING_CAMPAIGN_BLUEPRINTS), dispatch(ACT.SYNC_DUNNING_EXCLUSIONS)]);
    },
    async [ACT.SYNC_DUNNING_CAMPAIGN_BLUEPRINTS]({ state, commit, dispatch }: IActionContext) {
      try {
        // fetch campaign blueprints and email counts
        state.org?.dunning?.campaignBlueprints?.forEach((id) => {
          commit(MUTATE.STORE_DUNNING_CAMPAIGN_BLUEPRINT_DATA, { id, data: { loadState: LOAD_STATE.LOADING } });
        });
        dispatch(ACT.FETCH_DUNNING_CAMPAIGN_COUNTS);
        const thirtyDaysAgo = timeDay.floor(timeDay.offset(new Date(), -30));
        const [campaignBlueprints, dunningEmailCounts] = await Promise.all([
          DunningService.getAllCampaignBlueprints(),
          StatsService.dunningEmailCounts({
            filter: {
              startDate: thirtyDaysAgo,
              breakdown: ['campaignBlueprintId'],
            },
          }),
        ]);
        campaignBlueprints?.forEach((campaignBlueprint) => {
          commit(MUTATE.STORE_DUNNING_CAMPAIGN_BLUEPRINT_DATA, {
            id: campaignBlueprint._id,
            data: {
              ...campaignBlueprint,
              loadState: LOAD_STATE.LOADED,
              saveState: SAVE_STATE.SAVED,
            },
          });
          const emailCount = dunningEmailCounts.find(({ campaignBlueprintId }) => {
            return campaignBlueprintId === campaignBlueprint._id;
          }) || { count: 0, invoiceAmount: 0 };
          commit(MUTATE.STORE_DUNNING_CAMPAIGN_BLUEPRINT_EMAIL_COUNT, {
            id: campaignBlueprint._id,
            data: {
              ...emailCount,
              loadState: LOAD_STATE.LOADED,
              saveState: SAVE_STATE.SAVED,
            },
          });
        });
      } catch (e) {
        console.error('Error syncing campaign blueprints:', e);
        state.org?.dunning?.campaignBlueprints?.forEach((id) => {
          commit(MUTATE.STORE_DUNNING_CAMPAIGN_BLUEPRINT_DATA, { id, data: { loadState: LOAD_STATE.ERROR_LOADING } });
          commit(MUTATE.STORE_DUNNING_CAMPAIGN_BLUEPRINT_EMAIL_COUNT, { id, data: { loadState: LOAD_STATE.ERROR_LOADING } });
        });
      }
    },

    async [ACT.SYNC_DUNNING_EXCLUSIONS]({ state, commit }: IActionContext) {
      try {
        // fetch dunning exclusions and block counts
        state.org?.dunning?.exclusions?.forEach((id) => {
          commit(MUTATE.STORE_DUNNING_EXCLUSION_DATA, { id, data: { loadState: LOAD_STATE.LOADING } });
        });
        const thirtyDaysAgo = timeDay.floor(timeDay.offset(new Date(), -30));
        const [exclusions, blockCounts] = await Promise.all([
          DunningService.getAllExclusions(),
          StatsService.blockCounts({
            filter: {
              startDate: thirtyDaysAgo,
              breakdown: ['exclusionId'],
            },
          }),
        ]);
        exclusions?.forEach((exclusion) => {
          commit(MUTATE.STORE_DUNNING_EXCLUSION_DATA, {
            id: exclusion._id,
            data: {
              ...exclusion,
              loadState: LOAD_STATE.LOADED,
              saveState: SAVE_STATE.SAVED,
            },
          });
          const blockCount = blockCounts.find(({ exclusionId }) => {
            return exclusionId === exclusion._id;
          }) || { count: 0 };
          commit(MUTATE.STORE_DUNNING_EXCLUSION_BLOCK_COUNT, {
            id: exclusion._id,
            data: {
              ...blockCount,
              loadState: LOAD_STATE.LOADED,
              saveState: SAVE_STATE.SAVED,
            },
          });
        });
      } catch (e) {
        console.error('Failed to sync dunning exclusions:', e);
        state.org?.dunning?.exclusions?.forEach((id) => {
          commit(MUTATE.STORE_DUNNING_EXCLUSION_DATA, { id, data: { loadState: LOAD_STATE.ERROR_LOADING } });
          commit(MUTATE.STORE_DUNNING_EXCLUSION_BLOCK_COUNT, { id, data: { loadState: LOAD_STATE.ERROR_LOADING } });
        });
      }
    },
    async [ACT.FETCH_DUNNING_CAMPAIGN_BLUEPRINT]({ commit }: IActionContext, id: string) {
      commit(MUTATE.STORE_DUNNING_CAMPAIGN_BLUEPRINT_DATA, { id, data: { loadState: LOAD_STATE.LOADING } });
      try {
        const raw = await DunningService.getCampaignBlueprint(id);
        commit(MUTATE.STORE_DUNNING_CAMPAIGN_BLUEPRINT_DATA, { id, data: raw });
        commit(MUTATE.STORE_DUNNING_CAMPAIGN_BLUEPRINT_DATA, { id, data: { loadState: LOAD_STATE.LOADED, saveState: SAVE_STATE.SAVED } });
      } catch (e) {
        console.error('Failed to fetch campaign blueprint:', e);
        commit(MUTATE.STORE_DUNNING_CAMPAIGN_BLUEPRINT_DATA, { id, data: { loadState: LOAD_STATE.ERROR_LOADING } });
      }
    },
    async [ACT.CREATE_DUNNING_CAMPAIGN_BLUEPRINT]({ state }: IActionContext, options: { segmented: boolean }) {
      try {
        // generate a template
        const team = await OrgService.getMembers(state.user?.org as string);
        const admin = team.find((user) => user.role === 'ORG_OWNER');
        const user = admin || (state.user as IUser);
        const campaign = generateDunningSequence({
          from: user.email,
          replyTo: user.email,
          company: state.org?.name || '',
          senderName: user.name || '',
          ...options,
        });
        const campaignBlueprint = await DunningService.createCampaignBlueprint(campaign);
        return campaignBlueprint;
      } catch (e) {
        console.error('Failed to create campaign blueprint:', e);
        return null;
      }
    },
    // persist changes to DB
    async [ACT.UPDATE_DUNNING_CAMPAIGN_BLUEPRINT]({ commit, state }: IActionContext, { id }: { id: string }) {
      try {
        // emails that haven't been deleted
        const emailsToKeep = state.campaignBlueprints[id].emails.filter((e) => !e.delete);
        const smsToKeep = (state.campaignBlueprints[id].sms || []).filter((s) => !s.delete);
        commit(MUTATE.STORE_DUNNING_CAMPAIGN_BLUEPRINT_DATA, { id, data: { saveState: SAVE_STATE.SAVING, emails: emailsToKeep, sms: smsToKeep } });

        const updated = await DunningService.updateCampaignBlueprint(id, {
          data: state.campaignBlueprints[id],
        });
        const { newBlueprint } = updated;
        // change to saved if it hasn't been marked as unsaved while saving
        if (state.campaignBlueprints[id].saveState === SAVE_STATE.SAVING) {
          commit(MUTATE.STORE_DUNNING_CAMPAIGN_BLUEPRINT_DATA, {
            id,
            data: { saveState: SAVE_STATE.SAVED, updatedAt: newBlueprint.updatedAt },
          });
        }

        // Update state with the latest email data
        for (const email of state.campaignBlueprints[id].emails) {
          const emailId = email.guid;
          const data: Partial<ICampaignBlueprint> = { saveState: SAVE_STATE.SAVED };
          // Store _id of any newly created emails
          if (!email._id) {
            const newEmail = newBlueprint.emails.find((e) => e.guid === emailId);
            data._id = newEmail?._id;
          }
          commit(MUTATE.STORE_DUNNING_EMAIL_DATA, { campaignId: id, messageGuid: emailId, data });
        }

        // Update state with the latest SMS data
        for (const sms of state.campaignBlueprints[id].sms || []) {
          const smsId = sms.guid;
          const data: Partial<ICampaignBlueprint> = { saveState: SAVE_STATE.SAVED };
          // Store _id of any newly created sms
          if (!sms._id) {
            const newSms = (newBlueprint.sms || []).find((s) => s.guid === smsId);
            data._id = newSms?._id;
          }
          commit(MUTATE.STORE_DUNNING_SMS_DATA, { campaignId: id, messageGuid: smsId, data });
        }

        return updated;
      } catch (e) {
        console.error('Campaign blueprint update error:', e);
        return null;
      }
    },

    async [ACT.CLONE_DUNNING_CAMPAIGN_BLUEPRINT]({ dispatch }: IActionContext, { id }: { id: string }) {
      try {
        const campaignBlueprint = await DunningService.cloneCampaignBlueprint(id);
        await dispatch(ACT.SYNC_DUNNING);
        return campaignBlueprint;
      } catch (error) {
        console.error('Failed to clone campaign blueprint:', error);
        return null;
      }
    },

    async [ACT.SEND_DUNNING_TEST_SMS](
      _context: IActionContext,
      { campaignBlueprintId, smsBlueprintGuid, phoneNumber }: { campaignBlueprintId: string; smsBlueprintGuid: string; phoneNumber: string }
    ) {
      try {
        return await DunningService.sendTestSms(campaignBlueprintId, smsBlueprintGuid, phoneNumber);
      } catch (error: any) {
        return { error: error.message || 'Unknown error!' };
      }
    },

    async [ACT.SEND_DUNNING_TEST_EMAIL](
      _context: IActionContext,
      { campaignBlueprintId, emailBlueprintId, emailTo }: { campaignBlueprintId: string; emailBlueprintId: number; emailTo: string }
    ) {
      try {
        return await DunningService.sendTestEmail(campaignBlueprintId, emailBlueprintId, emailTo);
      } catch (error: any) {
        return { error: error.message || 'Unknown error!' };
      }
    },

    async [ACT.GENERATE_DUNNING_EMAIL_PREVIEW](
      _context: IActionContext,
      { campaignBlueprintId, emailBlueprintId }: { campaignBlueprintId: string; emailBlueprintId: number }
    ) {
      try {
        return await DunningService.generateEmailPreview(campaignBlueprintId, emailBlueprintId);
      } catch (error: any) {
        return { error: error.message || 'Unknown error!' };
      }
    },

    async [ACT.SCHEDULE_SAVE_DUNNING_CAMPAIGN_BLUEPRINT](
      { commit, dispatch, state, getters }: IActionContext,
      { id, delay }: { id: string; delay?: number }
    ) {
      const batchUpdateDelay = delay !== undefined ? delay : 2000;
      commit(MUTATE.STORE_DUNNING_CAMPAIGN_BLUEPRINT_DATA, { id, data: { saveState: SAVE_STATE.UNSAVED } });
      if (!getters.hasPermission('dunning:write')) return;
      const existingBatchUpdateTimeout = state.campaignBlueprints[id].batchUpdateTimeout;
      if (existingBatchUpdateTimeout) {
        clearTimeout(existingBatchUpdateTimeout);
      }
      const batchUpdate = () => {
        dispatch(ACT.UPDATE_DUNNING_CAMPAIGN_BLUEPRINT, { id });
      };
      const batchUpdateTimeout = setTimeout(batchUpdate, batchUpdateDelay);
      commit(MUTATE.STORE_DUNNING_CAMPAIGN_BLUEPRINT_DATA, { id, data: { batchUpdateTimeout } });
    },

    async [ACT.UPDATE_DUNNING_CAMPAIGN_BLUEPRINTS_ORDER]({ getters }: IActionContext, { campaignBlueprints }: { campaignBlueprints: string[] }) {
      try {
        if (!getters.hasPermission('dunning:write')) return;
        const orgId = getters.org._id;
        await OrgService.reorderCampaignBlueprints(orgId, { campaignBlueprints });
        return true;
      } catch (error) {
        console.error('Failed to reorder campaign blueprints:', error);
        return null;
      }
    },

    async [ACT.PUBLISH_DUNNING_CAMPAIGN_BLUEPRINT]({ state, dispatch, commit }: IActionContext, { id }: { id: string }) {
      try {
        // freeze operations during publish
        const campaignBlueprint = state.campaignBlueprints[id];
        // update if unsaved
        if (campaignBlueprint.saveState === SAVE_STATE.UNSAVED) {
          if (campaignBlueprint.batchUpdateTimeout) clearTimeout(campaignBlueprint.batchUpdateTimeout);
          await dispatch(ACT.UPDATE_DUNNING_CAMPAIGN_BLUEPRINT, { id });
        }
        const result = await DunningService.publishCampaignBlueprint(id);
        const { updatedAt, publishedAt, publishedCopies } = result.campaignBlueprint;
        commit(MUTATE.STORE_DUNNING_CAMPAIGN_BLUEPRINT_DATA, {
          id,
          data: {
            updatedAt,
            publishedAt,
            publishedCopies,
          },
        });
        return result;
      } catch (e) {
        console.error('Failed to publish campaign blueprint:', e);
        commit(MUTATE.STORE_DUNNING_CAMPAIGN_BLUEPRINT_DATA, { id, data: { saveState: SAVE_STATE.ERROR_SAVING } });
        return null;
      }
    },

    async [ACT.CREATE_DUNNING_EXCLUSION]({ dispatch }: IActionContext, { data }: { data: Omit<IExclusion, '_id'> }) {
      const { exclusion } = await DunningService.createExclusion(data);
      await dispatch(ACT.SYNC_DUNNING);
      return exclusion;
    },

    // persist changes to DB
    async [ACT.UPDATE_DUNNING_EXCLUSION]({ commit, state }: IActionContext, { id }: { id: string }) {
      try {
        commit(MUTATE.STORE_DUNNING_EXCLUSION_DATA, { id, data: { saveState: SAVE_STATE.SAVING } });

        const updated = await DunningService.updateExclusion(id, {
          data: state.dunningExclusions[id],
        });
        const { newExclusion } = updated;
        // change to saved if it hasn't been marked as unsaved while saving
        if (state.dunningExclusions[id].saveState === SAVE_STATE.SAVING) {
          commit(MUTATE.STORE_DUNNING_EXCLUSION_DATA, {
            id,
            data: { saveState: SAVE_STATE.SAVED, updatedAt: newExclusion.updatedAt },
          });
        }
        return updated;
      } catch (e) {
        console.error('Failed to update dunning exclusion:', e);
        commit(MUTATE.STORE_DUNNING_EXCLUSION_DATA, { id, data: { saveState: SAVE_STATE.ERROR_SAVING } });
        return null;
      }
    },

    async [ACT.CLONE_DUNNING_EXCLUSION]({ dispatch }: IActionContext, { id }: { id: string }) {
      try {
        const exclusion = await DunningService.cloneExclusion(id);
        await dispatch(ACT.SYNC_DUNNING);
        return exclusion;
      } catch (error) {
        console.error('Failed to clone campaign blueprint:', error);
        return null;
      }
    },

    async [ACT.SCHEDULE_SAVE_DUNNING_EXCLUSION]({ commit, dispatch, state, getters }: IActionContext, { id, delay }: { id: string; delay?: number }) {
      const batchUpdateDelay = delay !== undefined ? delay : 2000;
      commit(MUTATE.STORE_DUNNING_EXCLUSION_DATA, { id, data: { saveState: SAVE_STATE.UNSAVED } });
      if (!getters.hasPermission('dunning:write')) return;
      const existingBatchUpdateTimeout = state.dunningExclusions[id].batchUpdateTimeout;
      if (existingBatchUpdateTimeout) {
        clearTimeout(existingBatchUpdateTimeout);
      }
      const batchUpdate = () => {
        dispatch(ACT.UPDATE_DUNNING_EXCLUSION, { id });
      };
      const batchUpdateTimeout = setTimeout(batchUpdate, batchUpdateDelay);
      commit(MUTATE.STORE_DUNNING_EXCLUSION_DATA, { id, data: { batchUpdateTimeout } });
    },

    async [ACT.UPDATE_DUNNING_EXCLUSIONS_ORDER]({ getters }: IActionContext, { exclusions }: { exclusions: string[] }) {
      try {
        if (!getters.hasPermission('dunning:write')) return;
        const orgId = getters.org._id;
        await OrgService.reorderDunningExclusions(orgId, { exclusions });
        return true;
      } catch (error) {
        console.error('Failed to reorder dunning exclusions:', error);
        return null;
      }
    },

    async [ACT.PUBLISH_DUNNING_EXCLUSION]({ state, dispatch, commit }: IActionContext, { id }: { id: string }) {
      try {
        // freeze operations during publish
        const exclusion = state.dunningExclusions[id];
        // update if unsaved
        if (exclusion.saveState === SAVE_STATE.UNSAVED) {
          if (exclusion.batchUpdateTimeout) clearTimeout(exclusion.batchUpdateTimeout);
          await dispatch(ACT.UPDATE_DUNNING_EXCLUSION, { id });
        }
        const result = await DunningService.publishExclusion(id);
        const { updatedAt, publishedAt, publishedCopies } = result.exclusion;
        commit(MUTATE.STORE_DUNNING_EXCLUSION_DATA, {
          id,
          data: {
            updatedAt,
            publishedAt,
            publishedCopies,
          },
        });
        return result;
      } catch (e) {
        console.error('Failed to publish dunning exclusion:', e);
        commit(MUTATE.STORE_DUNNING_EXCLUSION_DATA, { id, data: { saveState: SAVE_STATE.ERROR_SAVING } });
        return null;
      }
    },

    async [ACT.DUNNING_WAITLIST_SIGNUP]({ commit, state }: IActionContext) {
      try {
        if (state.org?._id) {
          commit(MUTATE.SET_ORG_DETAILS, { dunningWaitlist: true });
          await OrgService.update(state.org._id, { dunningWaitlist: true });
        }
      } catch (e) {
        console.error('Failed to update dunning waitlist:', e);
      }
    },

    async [ACT.FETCH_DUNNING_CAMPAIGN_COUNTS]({ state, commit }: IActionContext) {
      if (state.dunningCampaignCountsLoadState === LOAD_STATE.LOADING) return;
      commit(MUTATE.SET_DUNNING_CAMPAIGNS_COUNT_LOAD_STATE, LOAD_STATE.LOADING);
      const activeCampaignCounts = await StatsService.dunningCampaignCount({
        filter: {
          startDate: timeDay.floor(timeDay.offset(new Date(), -60)),
          active: true,
        },
        breakdown: ['campaignBlueprintId'],
      });
      const campaignCountMap = activeCampaignCounts.reduce(
        (acc, { campaignBlueprintId, count }) => {
          if (campaignBlueprintId) {
            acc[campaignBlueprintId] = count;
          }
          return acc;
        },
        {} as Record<string, number>
      );
      commit(MUTATE.SET_DUNNING_CAMPAIGNS_COUNT, campaignCountMap);
      commit(MUTATE.SET_DUNNING_CAMPAIGNS_COUNT_LOAD_STATE, LOAD_STATE.LOADED);
    },

    // async [ACT.DELETE_EMAIL_BLUEPRINT]({ commit, state }: IActionContext, { campaignId, emailId }: { campaignId: string; emailId: string }) {
    //   try {
    //     commit(MUTATE.STORE_DUNNING_CAMPAIGN_BLUEPRINT_DATA, { id: campaignId, data: { saveState: SAVE_STATE.SAVING } });
    //     const removed = await DunningService.deleteEmailBlueprint({ campaignId, emailId });
    //     const newCampaignEmails = state.campaignBlueprints[campaignId].emails.filter((e) => e.guid !== emailId);
    //     commit(MUTATE.STORE_DUNNING_CAMPAIGN_BLUEPRINT_DATA, { id: campaignId, data: { emails: newCampaignEmails, saveState: SAVE_STATE.UNSAVED } });
    //   } catch (e) {
    //     console.error('Failed to delete email blueprint:', e);
    //     commit(MUTATE.STORE_DUNNING_CAMPAIGN_BLUEPRINT_DATA, { id: campaignId, data: { saveState: SAVE_STATE.ERROR_SAVING } });
    //   }
    // },
  },

  getters: {
    dunning(state: IState) {
      return state.org?.dunning;
    },
    dunningAvailable(state: IState, getters: any) {
      return getters.provider === 'stripe';
      // return state?.org?.dunningBeta;
    },
    dunningABTestsEnabled() {
      return false;
    },
    dunningCustomAttributesEnabled() {
      return true;
    },
    dunningWaitlist(state: IState) {
      return state?.org?.dunningWaitlist;
    },
    dunningSmsEnabled(state: IState) {
      return state?.org?.dunning?.smsEnabled;
    },
    dunningOnboardState(state: IState, getters: any) {
      return {
        campaignCreated: !!getters.primaryCampaignBlueprintId,
        emailReady: getters.senderDomainReady,
        billingPageReady: getters.isBillingPageReady,
        activateReady: getters.isReadyToActivate,
        activated: state.org?.dunning?.enabled,
      };
    },
    senderDomainReady(state: IState) {
      return (state.org?.dunning?.senderDomains || []).some((domain: any) => domain?.returnPathVerified && domain?.dkimVerified);
    },
    subdomain(state: IState) {
      return state.org?.dunning?.checkoutPage?.ckSubdomain;
    },
    customDomain(state: IState) {
      // This is the PRIMARY custom domain to be linked on emails:
      return state.org?.dunning?.checkoutPage?.customDomain;
    },
    hostedBillingPage(state: IState, getters: any) {
      if (getters.customDomain?.url) {
        return `https://${getters.customDomain.url}`;
      }
      return null;
    },
    customDomains(state: IState) {
      // Plural, this is the list of custom domains:
      return state.org?.dunning?.checkoutPage?.customDomains;
    },
    hasCustomDomain(state: IState) {
      return !!(state.org?.dunning?.checkoutPage?.customDomains || []).some((domain: any) => !!domain?.url);
    },
    isCustomDomainReady(state: IState, getters: any) {
      // return getters.customDomains?.some((domain: any) => domain?.isResolving && domain?.hasSSL);
      // Check the primary custom domain, stored in the single field customDomain:
      return getters.customDomain?.isResolving && getters.customDomain.hasSSL;
    },
    isBillingPageReady(state: IState, getters: any) {
      return getters.isCustomDomainReady || getters.subdomain;
    },
    isReadyToActivate(state: IState, getters: any) {
      return getters.isBillingPageReady && getters.senderDomainReady;
    },
    currentCampaignBlueprintId(state: IState) {
      return state.currentCampaignBlueprintId;
    },
    currentCampaignBlueprint(state: IState) {
      if (state.currentCampaignBlueprintId) {
        return state.campaignBlueprints[state.currentCampaignBlueprintId] || null;
      }
      return null;
    },
    currentCampaignBlueprintLoadState(state: IState, getters: any) {
      if (getters.currentCampaignBlueprint) {
        return getters.currentCampaignBlueprint.loadState;
      }
      return undefined;
    },
    currentDunningCampaignBlueprintSaveState(state: IState, getters: any) {
      if (getters.currentCampaignBlueprint) {
        return getters.currentCampaignBlueprint.saveState || SAVE_STATE.SAVED;
      }
      return undefined;
    },
    // NEW: equivalent to currentCampaignEmailBlueprints, but unifying emails+sms
    currentCampaignMessageGroups(state: IState, getters: any) {
      const emails = getters.currentCampaignBlueprint.emails as IEmailBlueprint[];
      const sms = getters.currentCampaignBlueprint.sms as ISmsBlueprint[];
      const messages: IDunningMessageGroup[] = getUnifiedMessages(emails, sms);
      return [...messages].sort((a, b) => ascending(a.email?.sendOnDay, b.email?.sendOnDay));
    },
    /* if SMS feature is disabled, only returns messages with email enabled */
    relevantMessageGroups(state: IState, getters: any) {
      if (getters.dunningSmsEnabled) {
        return getters.currentCampaignMessageGroups;
      }
      return getters.currentCampaignMessageGroups.filter((m: IDunningMessageGroup) => m.enableEmail);
    },
    // NEW: equivalent to currentCampaignEmailBlueprintMap, but unifying emails+sms
    currentCampaignMessageGroupMap(state: IState, getters: any) {
      if (getters.currentCampaignMessageGroups) {
        const messages = getters.currentCampaignMessageGroups as IDunningMessageGroup[];
        return messages.reduce(
          (acc, message) => {
            acc[message.guid] = message;
            return acc;
          },
          {} as { [key: string]: IDunningMessageGroup }
        );
      }
      return {};
    },
    // NEW: equivalent to currentEmailBlueprint, but unifying emails+sms
    currentCampaignMessageGroup(state: IState, getters: any) {
      if (state.currentEmailBlueprintId) {
        return getters.currentCampaignMessageGroupMap[state.currentEmailBlueprintId];
      }
      return null;
    },
    currentCampaignEmailBlueprints(state: IState, getters: any): string[] {
      if (getters.currentCampaignBlueprint) {
        return [...getters.currentCampaignBlueprint.emails].sort((a: IEmailBlueprint, b: IEmailBlueprint) => ascending(a.sendOnDay, b.sendOnDay));
      }
      return [];
    },
    currentCampaignEmailBlueprintMap(state: IState, getters: any) {
      if (getters.currentCampaignEmailBlueprints) {
        const emailBlueprints = getters.currentCampaignEmailBlueprints as IEmailBlueprint[];
        return emailBlueprints.reduce(
          (acc, emailBlueprint) => {
            acc[emailBlueprint.guid] = emailBlueprint;
            return acc;
          },
          {} as { [key: string]: IEmailBlueprint }
        );
      }
      return {};
    },
    currentEmailBlueprint(state: IState, getters: any) {
      try {
        return state.campaignBlueprints[getters.currentCampaignBlueprintId].emails.find(
          (emailBlueprint) => emailBlueprint.guid === state.currentEmailBlueprintId
        );
      } catch (e) {
        return null;
      }
    },
    currentSmsBlueprint(state: IState, getters: any) {
      const campaignBlueprint = state.campaignBlueprints[getters.currentCampaignBlueprintId];
      return campaignBlueprint?.sms?.find(
        (smsBlueprint) => smsBlueprint.guid === state.currentEmailBlueprintId // Rename to currentMessageGuid?
      );
    },
    baseDunningMessage(state: IState) {
      const user = state.user as IUser;
      const campaign = generateDunningSequence({
        from: user.email,
        replyTo: user.email,
        company: state.org?.name || '',
        senderName: user.name || '',
        segmented: false, // does not matter, does not affect emails
      });
      const newMessage: IDunningMessageGroup = {
        guid: campaign.emails[0].guid,
        email: campaign.emails[0],
        sms: campaign.sms![0],
        enableEmail: true,
        enableSms: false,
      };
      return newMessage;
    },
    campaignBlueprints(state: IState) {
      return Object.values(state.campaignBlueprints);
    },
    primaryCampaignBlueprintId(state: IState) {
      return state.org?.dunning?.activeCampaign;
    },
    primaryCampaignBlueprint(state: IState, getters: any) {
      if (getters.primaryCampaignBlueprintId) {
        return state.campaignBlueprints[getters.primaryCampaignBlueprintId] || null;
      }
      return null;
    },
    mergeFields(): EmailMergeField[] {
      return [
        {
          label: 'First Name',
          id: 'FIRST_NAME',
          fallback: '',
        },
        {
          label: 'Card Brand',
          id: 'CARD_BRAND',
          fallback: '',
        },
        {
          label: 'Last 4 of Card',
          id: 'LAST_4',
          fallback: 'xxxx',
        },
        {
          label: 'Plan Name',
          id: 'PLAN_NAME',
          fallback: '',
        },
        {
          label: 'Discount Amount',
          id: 'DISCOUNT_AMOUNT',
          fallback: '',
        },
        {
          label: 'Discount Duration',
          id: 'DISCOUNT_DURATION',
          fallback: '',
        },
        {
          label: 'Discount Full Description',
          id: 'DISCOUNT_DESCRIPTION',
          fallback: '',
        },
        {
          label: 'Discount Short Description',
          id: 'DISCOUNT_DESCRIPTION_SHORT',
          fallback: '',
        },
        {
          label: 'Plan Charge Amount',
          id: 'PLAN_AMOUNT',
          fallback: '',
        },
        {
          label: 'Plan Charge Amount (Abbreviated)',
          id: 'PLAN_AMOUNT_SHORT',
          fallback: '',
        },
      ];
    },
    dunningExclusions(state: IState) {
      return Object.values(state.dunningExclusions);
    },
    dunningProduct(state: IState, getters: any) {
      return getProductByName(state, ProductName.dunning);
    },
    hasDunningSubscription(state: IState, getters: any) {
      if (getters.hasLegacyPricing) {
        return true;
      }
      return hasProductSubscription(state, ProductName.dunning);
    },
    hasNonTrialDunningSubscription(state: IState, getters: any) {
      if (getters.hasLegacyPricing) {
        return true;
      }
      return hasNonTrialProductSubscription(state, ProductName.dunning);
    },
    hasTrialDunningSubscription(state: IState) {
      return hasTrialSubscription(state, ProductName.dunning);
    },
    isDunningCustomDomainVerified(state: IState, getters: any) {
      const domains = getters.customDomains;

      if (!domains) {
        return false;
      }

      return domains.some(customDomainIsValid);
    },
    isDunningSenderDomainVerified(state: IState, getters: any) {
      const domains = state.org?.dunning?.senderDomains || [];

      return domains.some((domain) => domain?.returnPathVerified && domain?.dkimVerified);
    },
    allRequiredDunningStepsCompleted(state: IState, getters: any) {
      return [
        getters.paymentProcessorStepCompleted,
        getters.isDunningCustomDomainVerified,
        getters.isDunningSenderDomainVerified,
        getters.primaryCampaignBlueprintId,
      ].every(Boolean);
    },
    isDunningProductLive(state: IState, getters: any) {
      if (getters.hasLegacyPricing) {
        return true;
      }
      return getters.dunningProduct?.isLive || false;
    },
    isDunningProductAvailable(state: IState, getters: any) {
      return getters.dunningProduct?.isAvailable || false;
    },
    isDunningProductTrial(state: IState, getters: any) {
      return getters.dunningProduct?.isTrial || false;
    },
  },
};

export default dunningModule;
