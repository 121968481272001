import { LOAD_STATE, getBlueprintDataFromRaw } from '@/store/shared';
import * as types from './mutation-types';
import { IStripeCoupon, IStripePrice, IStripeProduct } from './stripe-types';
import { IBlueprint, IOrg, IIssue, isISurveyStep, INotifications, ISegment, IState, IUser, ISegmentFilter, LoadState } from './types';
import * as Sentry from '@sentry/vue';
import { analytics } from '@/services/analytics.service';

const storeBlueprintData = (state: IState, { id, data }: { id: string; data: any }) => {
  const blueprintData = {
    ...state.blueprints[id],
    ...data,
  };
  state.blueprints = {
    ...state.blueprints,
    [id]: blueprintData,
  };
};

const storeBlueprintDataFromRaw = (state: IState, { id, raw }: { id: string; raw: IBlueprint }) => {
  const data = getBlueprintDataFromRaw(raw);
  const blueprintData = {
    ...state.blueprints[id],
    ...data,
    loadState: LOAD_STATE.LOADED,
  };
  state.blueprints = {
    ...state.blueprints,
    [id]: blueprintData,
  };
};

const storeSegmentData = (state: IState, { id, data }: { id: string; data: any }) => {
  const segmentData = {
    ...state.segments[id],
    ...data,
  };
  state.segments = {
    ...state.segments,
    [id]: segmentData,
  };
};

const storeABTestData = (state: IState, { id, data }: { id: string; data: any }) => {
  if (state.org && state.org.abTests) {
    const newAbTests = state.org.abTests.map((abTest) => {
      if (abTest._id === id) {
        return {
          ...abTest,
          ...data,
        };
      }
      return abTest;
    });
    state.org.abTests = newAbTests;
  }
};

const storeWebhookData = (state: IState, { id, data }: { id: string; data: any }) => {
  const webhookData = {
    ...state.webhooks[id],
    ...data,
  };
  state.webhooks = {
    ...state.webhooks,
    [id]: webhookData,
  };
};

export default {
  [types.SET_AUTH_LOADING](state: IState) {
    state.authStatus = 'loading';
  },
  [types.SET_AUTH_SUCCESS](state: IState, token: string) {
    state.authStatus = 'success';
    state.token = token;
  },
  [types.SET_AUTH_2FA_REQUIRED](state: IState) {
    state.twoFactorStatus = 'required';
  },
  [types.SET_USER](state: IState, user: IUser) {
    state.user = user;

    analytics.setUser(user);

    if (import.meta.env.PROD) {
      Sentry.setUser({ email: user.email, id: user._id, ip_address: '{{auto}}' });
    }
  },
  [types.SET_ORG](state: IState, org: IOrg) {
    state.org = org;

    // If this org was fetched with populated data, update state for those
    const { populatedBlueprints = [], populatedSegments = [] } = org;
    populatedSegments.forEach((segment: ISegment) => {
      storeSegmentData(state, {
        id: segment._id,
        data: {
          ...segment,
          loadState: LOAD_STATE.LOADED,
        },
      });
    });
    populatedBlueprints.forEach((blueprint: IBlueprint) => {
      storeBlueprintDataFromRaw(state, {
        id: blueprint._id,
        raw: {
          ...blueprint,
          loadState: LOAD_STATE.LOADED,
        },
      });
    });
  },
  [types.SET_ORGS](state: IState, orgs: IOrg[]) {
    state.orgs = orgs;
  },
  [types.SET_CONFIDENTIAL](state: IState, confidential: boolean) {
    state.confidential = confidential;
  },
  [types.SET_TEST_MODE](state: IState, testMode: boolean) {
    state.testMode = testMode;
  },
  [types.SET_STRIPE_COUPONS](state: IState, coupons: IStripeCoupon[]) {
    state.stripeData = {
      ...state.stripeData,
      coupons,
    };
  },
  [types.ADD_STRIPE_COUPON](state: IState, coupon: IStripeCoupon) {
    const coupons = state.stripeData?.coupons || [];
    if (coupons.find((c) => c.id === coupon.id)) {
      return;
    }
    state.stripeData = {
      ...state.stripeData,
      coupons: [...coupons, coupon],
    };
  },
  [types.SET_STRIPE_COUPONS_LOAD_STATE](state: IState, loadState: LoadState) {
    state.stripeData = {
      ...state.stripeData,
      couponsLoadState: loadState,
    };
  },
  [types.SET_STRIPE_PRICES](state: IState, prices: IStripePrice[]) {
    state.stripeData = {
      ...state.stripeData,
      prices,
    };
  },
  [types.SET_STRIPE_PRICES_LOAD_STATE](state: IState, loadState: LoadState) {
    state.stripeData = {
      ...state.stripeData,
      pricesLoadState: loadState,
    };
  },
  [types.SET_STRIPE_PRODUCTS](state: IState, products: IStripeProduct[]) {
    state.stripeData = {
      ...state.stripeData,
      products,
    };
  },
  [types.SET_STRIPE_PRODUCTS_LOAD_STATE](state: IState, loadState: LoadState) {
    state.stripeData = {
      ...state.stripeData,
      productsLoadState: loadState,
    };
  },
  [types.SET_USER_DATA](state: IState, payload: Partial<IUser>) {
    if (state.user) {
      state.user = {
        ...state.user,
        ...payload,
      };
    }
  },
  [types.SET_AUTH_2FA_ERROR_MESSAGE](state: IState, message: string) {
    state.twoFactorErrorMessage = message;
  },
  [types.SET_AUTH_ERROR](state: IState) {
    state.authStatus = 'error';
  },
  [types.SET_LOGOUT](state: IState) {
    state.authStatus = '';
    state.token = '';
  },

  [types.CLEAR_STATE](state: IState) {
    state.user = null;
    state.token = '';
    state.authStatus = null;
    state.twoFactorStatus = null;
    state.twoFactorErrorMessage = null;
    state.org = null;
    state.blueprints = {};
    state.currentBlueprintId = null;
    state.asOrg = null;
    state.stripeData = {
      couponsLoadState: LOAD_STATE.UNLOADED,
    };
    state.reactivationBlockCounts = {};
    state.reactivationBlockCountsLoadState = LOAD_STATE.UNLOADED;
    state.dunningExclusionBlockCounts = {};
    state.dunningExclusions = {};
    state.billingMetricsByTimeInterval = [];
  },
  [types.ADMIN_CLEAR_STATE](state: IState) {
    state.stripeData = {
      couponsLoadState: LOAD_STATE.UNLOADED,
      pricesLoadState: LOAD_STATE.UNLOADED,
      productsLoadState: LOAD_STATE.UNLOADED,
    };
    state.reactivationBlockCounts = {};
    state.reactivationBlockCountsLoadState = LOAD_STATE.UNLOADED;
    state.reactivationEmailTimeline = [];
    state.reactivationEmailTimelineLoadState = LOAD_STATE.UNLOADED;
    state.dunningEmailTimeline = [];
    state.dunningEmailTimelineLoadState = LOAD_STATE.UNLOADED;
    state.dunningExclusionBlockCounts = {};
    state.dunningExclusions = {};
    state.billingMetricsByTimeInterval = [];
  },
  [types.STORE_ABTEST_DATA](state: IState, { id, data }: { id: string; data: any }) {
    storeABTestData(state, { id, data });
  },
  [types.STORE_SEGMENT_DATA](state: IState, { id, data }: { id: string; data: any }) {
    storeSegmentData(state, { id, data });
  },
  [types.STORE_WEBHOOK_DATA](state: IState, { id, data }: { id: string; data: any }) {
    storeWebhookData(state, { id, data });
  },
  [types.SET_BILLING_METRICS_BY_TIME_INTERVAL](state: IState, payload: any[]) {
    state.billingMetricsByTimeInterval = payload;
  },
  [types.SET_SEGMENT_FILTER](state: IState, payload: { segmentId: string; index: number; data: ISegmentFilter }) {
    const { segmentId, index, data } = payload;
    if (state.segments[segmentId]) {
      const segment = state.segments[segmentId];
      let newFilters = [] as ISegmentFilter[];
      if (segment.filter) {
        newFilters = segment.filter.map((filter, i) => {
          if (i === index) {
            return data;
          }
          return filter;
        });
      } else {
        newFilters = [data];
      }
      storeSegmentData(state, { id: segmentId, data: { filter: newFilters } });
    }
  },
  [types.REMOVE_SEGMENT_FILTER](state: IState, { segmentId, index }: { segmentId: string; index: number }) {
    if (state.segments[segmentId]) {
      const segment = state.segments[segmentId];
      if (segment.filter) {
        const newFilters = segment.filter.filter((_, i) => i !== index);
        storeSegmentData(state, { id: segmentId, data: { filter: newFilters } });
      }
    }
  },
  // BLUEPRINT RELATED MUTATIONS
  [types.SET_CURRENT_BLUEPRINT_ID](state: IState, blueprintId: string) {
    state.currentBlueprintId = blueprintId;
  },
  [types.STORE_BLUEPRINT_DATA](state: IState, { id, data }: { id: string; data: any }) {
    storeBlueprintData(state, { id, data });
  },
  // maps from api to storage
  [types.STORE_BLUEPRINT_DATA_FROM_RAW](state: IState, { id, raw }: { id: string; raw: IBlueprint }) {
    storeBlueprintDataFromRaw(state, { id, raw });
  },
  // relies on currentBlueprintId - could probably update to take in step guid (and maybe normalize step data)
  [types.SET_BLUEPRINT_STEP](state: IState, payload: any) {
    if (state.currentBlueprintId) {
      const blueprint = state.blueprints[state.currentBlueprintId];
      const newSteps = blueprint.steps.map((step) => {
        if (step.guid === payload.guid) {
          return payload;
        }
        return step;
      });
      storeBlueprintData(state, { id: blueprint._id, data: { steps: newSteps } });
    }
  },
  // update a property on the survey object (within the step object) and propagate changes up
  [types.UPDATE_BLUEPRINT_SURVEY](state: IState, { surveyGuid, data }: { surveyGuid: string; data: any }) {
    if (state.currentBlueprintId) {
      const blueprint = state.blueprints[state.currentBlueprintId];
      const surveyStep = blueprint.steps.find((s) => isISurveyStep(s) && s.survey.guid === surveyGuid);
      if (surveyStep && isISurveyStep(surveyStep)) {
        const newSurveyStep = {
          ...surveyStep,
          survey: {
            ...surveyStep.survey,
            ...data,
          },
        };
        const newSteps = blueprint.steps.map((step) => {
          if (isISurveyStep(step) && step.survey.guid === surveyGuid) {
            return newSurveyStep;
          }
          return step;
        });
        storeBlueprintData(state, { id: blueprint._id, data: { steps: newSteps } });
      }
    }
  },
  [types.SET_ORG_DETAILS](state: IState, payload: any) {
    if (state.org) {
      state.org = { ...state.org, ...payload };
    }
  },
  [types.SET_ORG_NOTIFICATIONS](state: IState, notifications: INotifications) {
    if (state.org) {
      state.org.notifications = {
        ...state.org.notifications,
        ...notifications,
      };
    }
  },
  [types.SET_PREVIEW_INLINE](state: IState, value: boolean | false) {
    state.previewInline = value;
  },
  [types.SET_PREVIEW_MERGE_FIELDS](state: IState, payload: any) {
    state.previewMergeFields = payload;
  },
  [types.SET_SELECTED_TEMPLATE](state: IState, selectedTemplate: string | null) {
    state.selectedTemplate = selectedTemplate;
  },
  [types.SET_PROMO](state: IState, promo: string | null) {
    state.promo = promo;
  },
  [types.SET_PRODUCT_INTENT](state: IState, productIntent: string | null) {
    state.productIntent = productIntent;
  },
  [types.SET_AS_ORG](state: IState, asOrg: string | null) {
    state.asOrg = asOrg;
  },
  [types.SET_SELECTED_PLAN_TYPE](state: IState, selectedPlan: string) {
    state.selectedPlanType = selectedPlan;
  },
  [types.SET_ISSUES](state: IState, issues: IIssue[]) {
    state.issues = issues;
  },
  [types.SET_SYNC_USER_LOAD_STATE](state: IState, loadState: LoadState) {
    state.syncUserLoadState = loadState;
  },
  [types.SET_SYNC_ORG_LOAD_STATE](state: IState, loadState: LoadState) {
    state.syncOrgLoadState = loadState;
  },
  [types.ADMIN_STORE_ORG_DATA](state: IState, { orgId, path, value }: { orgId: string; path: string; value: any }) {
    if (state.orgs) {
      const newOrgs = state.orgs.map((org) => {
        if (org._id !== orgId) {
          return org;
        }
        const pathParts = path.split('.');
        let target = org;
        for (let i = 0; i < pathParts.length - 1; i++) {
          if (!target[pathParts[i]]) {
            target[pathParts[i]] = {};
          }

          target = target[pathParts[i]];
        }

        target[pathParts[pathParts.length - 1]] = value;
        return org;
      });
      state.orgs = newOrgs;
    }
  },
};
