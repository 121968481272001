<template>
  <div>
    <div>
      <p class="text-lg text-brand-black font-semibold">
        <slot></slot>
      </p>
    </div>
    <div class="flex items-center justify-between mt-6">
      <div class="flex items-center space-x-4">
        <img class="rounded-full h-full max-h-12" :src="imageAsset" />
        <div class="text-brand-brown font-semibold text-sm">
          <slot name="author"></slot>
        </div>
      </div>
      <div>
        <img src="@/assets/img/quotation-mark.svg" class="h-7" />
      </div>
    </div>
  </div>
</template>

<script>
import Veed from '@/assets/img/people/john-headshot.jpeg';
import OneUp from '@/assets/img/people/oneup-headshot.png';
import Jasper from '@/assets/img/people/jarvis-headshot.jpg';
import ImpeccableInvestor from '@/assets/img/people/impeccable-investor-headshot.jpg';
import Paperbell from '@/assets/img/people/paperbell-headshot.jpg';

export default {
  name: 'Testimonial',
  props: ['image'],
  data() {
    return {
      imageMap: {
        OneUp,
        Jasper,
        ImpeccableInvestor,
        Paperbell,
        Veed,
      },
    };
  },
  computed: {
    imageAsset() {
      return this.imageMap[this.image];
    },
  },
};
</script>
