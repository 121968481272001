import { Route } from 'vue-router';

const BASE_TITLE = 'Churnkey';

export function updateTitle(to: Route): void {
  const parts: string[] = [BASE_TITLE];

  // This will give us the hierarchy of the route
  to.matched.forEach((record) => {
    // Use label from meta if available
    if (record.meta?.label) {
      parts.push(record.meta.label);
    }
  });

  // Set the document title
  document.title = parts.join(' | ');
}
