import EventBus from '@/helpers/event-bus';
import { CAMPAIGN_SCHEDULE } from '@/store/shared';
import { IState, LAYOUT, NAV_GROUP } from '@/store/types';
import { RouteConfig } from 'vue-router';

const routes: RouteConfig[] = [
  // Legacy routes
  {
    path: '/dunning/config',
    redirect: '/dunning/advanced-settings',
  },
  {
    path: '/dunning-campaign/:id',
    redirect: '/dunning/campaigns/:id',
  },
  {
    path: '/dunning-exclusion/:id',
    redirect: '/dunning/exclusions/:id',
  },
  {
    path: '/dunning-dashboard',
    redirect: '/dunning/metrics',
  },
  // {
  //   path: '/events',
  //   redirect: '/admin/events'
  // },
  {
    path: '/flows/abtests',
    redirect: '/cancellation/abtests',
  },
  {
    path: '/flows/all',
    redirect: '/cancellation/flows',
  },
  {
    path: '/flows/design',
    redirect: '/cancellation/design',
  },
  {
    path: '/flows/embed',
    redirect: '/cancellation/embed-code',
  },
  {
    path: '/flows/settings',
    redirect: '/cancellation/advanced-settings',
  },
  {
    path: '/health',
    redirect: '/churn-prediction',
  },
  {
    path: '/settings/account',
    redirect: '/account',
  },
  {
    path: '/settings/billing',
    redirect: '/billing',
  },
  // Used for stripe success redirect passed on productName cancelFlow
  {
    path: '/cancelFlow',
    redirect: '/cancellation',
  },
  {
    path: '/settings/integrations/:provider',
    redirect: ({ params, query }) => ({
      path: '/settings/integrations',
      query: { ...query, ...params },
    }),
  },
  {
    path: '/settings/team',
    redirect: '/team',
  },
  {
    path: '/settings/dunning',
    redirect: '/dunning/advanced-settings',
  },
  {
    path: '/(settings/team|settings/confirm|auth/magiclink)/:authToken',
    component: () => import('@/views/AuthLink.vue'),
    meta: { layout: LAYOUT.NONE },
  },
  // TODO: add any missing links from docs

  // Production routes that need a higher match priority
  // TODO: Implement these as child routes under /dunning/campaigns
  // Similarly, move /builder/:id to /cancellations/flows/:id for congruence
  {
    path: '/dunning/campaigns/:id',
    name: 'dunningcampaign',
    component: () => import('@/views/DunningCampaignBuilder.vue'),
    meta: { layout: LAYOUT.NONE, requiresAuth: true, requiresAdmin: false, label: 'Campaign Builder' },
  },
  {
    path: '/dunning/exclusions/:id',
    name: 'dunningexclusion',
    component: () => import('@/views/DunningExclusionBuilder.vue'),
    meta: { layout: LAYOUT.NONE, requiresAuth: true, requiresAdmin: false, label: 'Exclusion Builder' },
  },

  // Production routes (sidebar nav)
  {
    // The Overview page is a placeholder for now. Remove the redirect and
    // uncomment the meta.navGroups property below when this is built out.
    redirect: '/cancellation/metrics',
    path: '/overview',
    name: 'overview',
    component: () => import('@/views/Overview.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      // navGroups: [NAV_GROUP.MAIN],
      icon: () => import('@/components/UI/SidebarIcons/OverviewIcon.vue'),
      dataControls: {
        confidential: true,
        testMode: true,
        // testMode: (state: IState, getters: any) => {
        // return state.recentSessionsLoadState !== LOAD_STATE.LOADED && state.recentSessions.length;
        // },
      },
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/ChurnMetricsHome.vue'),
    meta: {
      label: 'Home',
      requiresAuth: true,
      requiresAdmin: false,
      requiresFeature: 'churnMetricsEnabled',
      navGroups: [NAV_GROUP.MAIN],
      icon: () => import('@/components/UI/SidebarIcons/HomeDashboardIcon.vue'),
      available(state: IState, getters: any) {
        const isSupportedIntegration = !getters.isPaddleClassic || getters.paymentProvider !== 'braintree';
        return getters.isAdmin || isSupportedIntegration;
      },
    },
  },
  {
    path: '/admin-dashboard',
    name: 'admin-dashboard',
    component: () => import('@/views/ChurnMetricsHome.vue'),
    meta: {
      label: 'Home',
      requiresAuth: true,
      requiresAdmin: true,
      available(state: IState, getters: any) {
        const isSupportedIntegration = !getters.isPaddleClassic || getters.paymentProvider !== 'braintree';
        return getters.isAdmin || isSupportedIntegration;
      },
    },
  },
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      label: 'Home',
      requiresAuth: true,
      requiresAdmin: false,
    },
  },
  {
    path: '/cancellation',
    name: 'cancellation',
    component: () => import('@/views/Cancellation.vue'),
    redirect: '/cancellation/metrics',
    meta: {
      label: 'Cancel Flows',
      requiresAuth: true,
      requiresAdmin: false,
      navGroups: [NAV_GROUP.MAIN],
      icon: () => import('@/components/UI/SidebarIcons/CancelFlowsIcon.vue'),
    },
    children: [
      {
        name: 'cancellation.analytics',
        path: 'analytics',
        component: () => import('@/components/Cancellation/CancellationAnalytics.vue'),
        meta: {
          label: 'Analytics',
          dataControls: { confidential: true, testMode: true },
        },
      },
      {
        path: 'flows',
        component: () => import('@/components/Cancellation/CancellationFlows.vue'),
        meta: {
          label: 'Flows',
        },
      },
      {
        path: 'abtests',
        component: () => import('@/components/Cancellation/CancellationABTests.vue'),
        meta: { label: 'A/B Tests' },
      },
      {
        name: 'cancellation.abtests.details',
        path: 'abtests/:id',
        component: () => import('@/components/Cancellation/ABTests/CancellationABTest.vue'),
      },
      {
        path: 'design',
        component: () => import('@/components/Cancellation/CancellationDesign.vue'),
        meta: {
          label: 'Design',
        },
      },
      {
        path: 'embed-code',
        component: () => import('@/components/Cancellation/CancellationEmbedCode.vue'),
        meta: {
          label: 'Embed Code',
        },
      },
      {
        path: 'advanced-settings',
        component: () => import('@/components/Cancellation/CancellationAdvancedSettings.vue'),
        meta: {
          label: 'Settings',
        },
      },
      { path: '*', redirect: 'analytics' },
    ],
  },
  {
    path: '/dunning',
    name: 'dunning',
    component: () => import('@/views/Dunning.vue'),
    redirect: '/dunning/analytics',
    meta: {
      label: 'Payment Recovery',
      requiresAuth: true,
      requiresAdmin: false,
      navGroups: [NAV_GROUP.MAIN],
      icon: () => import('@/components/UI/SidebarIcons/FailedPaymentsIcon.vue'),
    },
    children: [
      {
        path: 'analytics',
        component: () => import('@/components/DunningAnalytics/DunningAnalytics.vue'),
        meta: {
          dataControls: { confidential: true },
          label: 'Analytics',
        },
      },
      {
        path: 'campaigns',
        component: () => import('@/components/Dunning/DunningCampaigns.vue'),
        meta: {
          label: 'Campaigns',
        },
      },
      {
        path: 'exclusions',
        component: () => import('@/components/Dunning/DunningExclusions.vue'),
        meta: {
          label: 'Exclusions',
        },
      },
      {
        path: 'advanced-settings',
        component: () => import('@/components/Dunning/DunningAdvancedSettings.vue'),
        meta: {
          label: 'Settings',
        },
      },
      { path: '*', redirect: 'analytics' },
    ],
  },
  {
    path: '/reactivation/preview',
    name: 'reactivationpreview',
    component: () => import('@/views/ReactivationPreview.vue'),
    meta: {
      label: 'Reactivations',
      requiresAuth: true,
      requiresAdmin: false,
      // navGroups: [NAV_GROUP.MAIN],
      icon: () => import('@/components/UI/SidebarIcons/ReactivationsIcon.vue'),
    },
  },
  {
    path: '/reactivation/campaigns/:id',
    name: 'reactivationcampaign',
    component: () => import('@/views/ReactivationCampaignBuilder.vue'),
    meta: { layout: LAYOUT.NONE, requiresAuth: true, requiresAdmin: false, label: 'Reactivation Campaign' },
  },
  {
    path: '/reactivation/campaigns/view/:id',
    name: 'reactivationcampaignview',
    component: () => import('@/views/ReactivationCampaignDetails.vue'),
    meta: { layout: LAYOUT.NONE, requiresAuth: true, requiresAdmin: false, label: 'Reactivation Campaign Details' },
  },
  {
    path: '/reactivation/exclusions/:id',
    name: 'reactivationexclusion',
    component: () => import('@/views/ReactivationExclusionBuilder.vue'),
    meta: { layout: LAYOUT.NONE, requiresAuth: true, requiresAdmin: false, label: 'Reactivation Exclusion' },
  },
  {
    path: '/reactivation',
    name: 'reactivation',
    component: () => import('@/views/Reactivation.vue'),
    redirect: '/reactivation/analytics',
    meta: {
      label: 'Reactivations',
      requiresAuth: true,
      requiresAdmin: false,
      navGroups: [NAV_GROUP.MAIN],
      icon: () => import('@/components/UI/SidebarIcons/ReactivationsIcon.vue'),
    },
    children: [
      {
        path: 'analytics',
        component: () => import('@/components/Reactivation/ReactivationAnalytics.vue'),
        meta: {
          dataControls: { confidential: true },
          label: 'Analytics',
        },
      },
      {
        path: 'recurring-campaigns',
        meta: {
          label: 'Recurring Campaigns',
          campaignSchedule: CAMPAIGN_SCHEDULE.RECURRING,
        },
        component: () => import('@/components/Reactivation/ReactivationCampaigns.vue'),
      },
      {
        path: 'one-time-campaigns',
        meta: {
          label: 'One-Time Campaigns',
          campaignSchedule: CAMPAIGN_SCHEDULE.ONE_TIME,
        },
        component: () => import('@/components/Reactivation/ReactivationCampaigns.vue'),
      },
      {
        path: 'exclusions',
        component: () => import('@/components/Reactivation/ReactivationExclusions.vue'),
        meta: {
          label: 'Exclusions',
        },
      },
      {
        path: 'advanced-settings',
        component: () => import('@/components/Reactivation/ReactivationSettings.vue'),
        meta: {
          label: 'Settings',
        },
      },
      { path: '*', redirect: 'analytics' },
    ],
  },
  {
    path: '/churn-prediction',
    name: 'churnprediction',
    component: () => import('@/views/HealthHome.vue'),
    meta: {
      label: 'Customer Health',
      requiresFeature: 'customerHealthEnabled',
      requiresAuth: true,
      requiresAdmin: false,
      navGroups: [NAV_GROUP.MAIN],
      icon: () => import('@/components/UI/SidebarIcons/ChurnPredictionIcon.vue'),
    },
  },
  {
    path: '/feedback-ai',
    name: 'feedbackai',
    component: () => import('@/views/FeedbackAnalysisHome.vue'),
    meta: {
      label: 'Feedback AI',
      requiresAuth: true,
      requiresAdmin: false,
      requiresFeature: 'feedbackAIEnabled',
      navGroups: [NAV_GROUP.MAIN],
      icon: () => import('@/components/UI/SidebarIcons/FeedbackAIIcon.vue'),
    },
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/Admin.vue'),
    redirect: '/admin/issues',
    meta: { requiresAuth: true, requiresAdmin: true },
    children: [
      {
        path: 'issues',
        component: () => import('@/components/Admin/AdminIssues.vue'),
      },
      {
        path: 'revenue',
        component: () => import('@/components/Admin/AdminRevenue.vue'),
      },
      {
        path: 'billing',
        component: () => import('@/components/Admin/AdminBilling.vue'),
      },
      {
        path: 'features',
        component: () => import('@/components/Admin/AdminFeatures.vue'),
      },
      {
        path: 'events',
        component: () => import('@/components/SubscriptionAnalytics/EventDashboard.vue'),
      },
    ],
  },
  // TEMP
  {
    path: '/events',
    name: 'events',
    component: () => import('@/components/SubscriptionAnalytics/EventDashboard.vue'),
  },
  {
    path: '/team',
    name: 'team',
    component: () => import('@/views/Team.vue'),
    meta: {
      label: 'Team',
      requiresAuth: true,
      requiresAdmin: false,
      navGroups: [NAV_GROUP.WORKSPACE],
      icon: () => import('@/components/UI/SidebarIcons/InviteTeamIcon.vue'),
    },
  },
  {
    path: '/settings',
    name: 'settingsview',
    component: () => import('@/views/Settings.vue'),
    redirect: '/settings/organization',
    meta: {
      label: 'Settings',
      requiresAuth: true,
      requiresAdmin: false,
      navGroups: [NAV_GROUP.WORKSPACE],
      icon: () => import('@/components/UI/SidebarIcons/SettingsIcon.vue'),
    },
    children: [
      {
        path: 'organization',
        component: () => import('@/components/Settings/SettingsOrganization.vue'),
        meta: {
          label: 'Organization',
        },
      },
      {
        path: 'billing-provider',
        component: () => import('@/components/Settings/SettingsBillingProvider.vue'),
        meta: {
          label: 'Billing Provider',
        },
      },
      {
        path: 'integrations',
        component: () => import('@/components/Settings/SettingsIntegrations.vue'),
        meta: {
          label: 'Integrations',
        },
      },
      // {
      //   path: 'coupons',
      //   name: 'coupons-settings',
      //   component: () => import('@/components/Settings/SettingsCoupons.vue'),
      //   meta: {
      //     label: 'Coupons',
      //   },
      // },
      {
        path: 'email',
        name: 'email-settings',
        component: () => import('@/components/Settings/SettingsEmail.vue'),
        meta: {
          label: 'Email',
          available(state: IState, getters: any) {
            return !!getters.orgShowEmailSettings;
          },
        },
      },
      {
        path: 'domain', // formerly: custom-domains
        name: 'domain-settings',
        component: () => import('@/components/Settings/SettingsCustomDomains.vue'),
        meta: {
          label: 'Domain',
          available(state: IState, getters: any) {
            return !!getters.orgShowEmailSettings;
            // return !!getters.orgSupportsManagedFlow;
          },
        },
      },
      {
        path: 'snippet', // formerly: embed
        component: () => import('@/components/Settings/SettingsEmbed.vue'),
        meta: {
          label: 'Embed',
        },
      },
      {
        path: 'issues',
        component: () => import('@/components/Settings/SettingsIssues.vue'),
        meta: {
          label: 'Issues',
        },
      },
      {
        path: 'company',
        component: () => import('@/components/Settings/SettingsCompany.vue'),
        meta: { hidden: true },
      },
      {
        path: 'connect',
        component: () => import('@/components/Settings/SettingsConnectStripe.vue'),
        meta: { hidden: true },
      },
      {
        path: 'braintree',
        component: () => import('@/components/Settings/SettingsConnectBraintree.vue'),
        meta: { hidden: true },
      },
      { path: '*', redirect: 'billing-provider' },
    ],
  },
  {
    path: '/billing/success',
    name: 'billing-success',
    component: () => import('@/views/BillingSuccess.vue'),
    meta: { layout: LAYOUT.NONE, requiresAuth: true, requiresAdmin: false },
  },
  {
    path: '/billing/cancel',
    name: 'billing-cancel',
    component: () => import('@/views/BillingCancel.vue'),
    meta: { layout: LAYOUT.NONE, requiresAuth: true, requiresAdmin: false },
  },
  {
    path: '/billing',
    name: 'billing',
    component: () => import('@/views/Billing.vue'),
    meta: {
      label: 'Billing',
      requiresAuth: true,
      requiresAdmin: false,
      requiresSubscription: true,
      navGroups: [NAV_GROUP.WORKSPACE],
      available(state: IState, getters: any) {
        return getters.hasAnyNonTrialSubscription;
      },
      icon: () => import('@/components/UI/SidebarIcons/BillingIcon.vue'),
    },
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('@/views/Account.vue'),
    meta: {
      label: 'Your Account',
      requiresAuth: true,
      requiresAdmin: false,
      navGroups: [NAV_GROUP.WORKSPACE],
      icon: () => import('@/components/UI/SidebarIcons/AccountIcon.vue'),
    },
  },
  {
    path: '/feedback',
    beforeEnter: (to, from, next) => {
      EventBus.$emit('userbackOpen');
      next(false); // prevent navigation
    },
    meta: {
      label: 'Issues & Feedback',
      requiresAuth: true,
      requiresAdmin: false,
      navGroups: [NAV_GROUP.WORKSPACE],
      icon: () => import('@/components/UI/SidebarIcons/HelpIcon.vue'), // Replace with the icon you want to use
    },
  },
  // {
  //   path: '/support',
  //   name: 'support',
  //   component: () => import('@/views/Support.vue'),
  //   meta: {
  //     requiresAuth: true,
  //     requiresAdmin: false,
  //     navGroups: [NAV_GROUP.WORKSPACE],
  //     icon: () => import('@/components/UI/SidebarIcons/HelpIcon.vue'),
  //   },
  // },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/Logout.vue'),
    meta: {
      label: 'Log Out',
      requiresAuth: true,
      requiresAdmin: false,
      navGroups: [NAV_GROUP.WORKSPACE],
      icon: () => import('@/components/UI/SidebarIcons/LogoutIcon.vue'),
    },
  },

  // Other production routes
  {
    path: '/onboard',
    name: 'onboard',
    component: () => import('@/views/Onboard.vue'),
    children: [
      {
        path: 'setup',
        component: () => import('@/components/Onboard/OnboardSetup.vue'),
        meta: { hidden: true },
      },
      {
        path: 'connect',
        component: () => import('@/components/Onboard/OnboardConnect.vue'),
        beforeEnter: async (to, from, next) => {
          // redirect to audit if applicable
          const hasAudit = localStorage.getItem('audit');
          if (hasAudit) {
            next({ path: `/onboard/audit` });
          } else {
            next();
          }
        },
        meta: { hidden: true },
      },
      {
        path: 'audit',
        component: () => import('@/components/Onboard/OnboardAudit.vue'),
        meta: { hidden: true },
      },
    ],
    meta: { requiresAuth: true, requiresAdmin: false },
  },
  {
    path: '/upgrade',
    name: 'upgrade',
    component: () => import('@/views/Upgrade.vue'),
    meta: { requiresAuth: true, requiresAdmin: false },
  },
  {
    path: '/lite',
    name: 'lite',
    component: () => import('@/views/Lite.vue'),
    meta: { requiresAuth: true, requiresAdmin: false },
  },
  {
    path: '/customer/:id',
    name: 'customerprofile',
    component: () => import('@/views/CustomerProfile.vue'),
    meta: { requiresAuth: true, requiresAdmin: false },
  },
  {
    path: '/auth/magiclink/:id',
    name: 'resetpassword',
    component: () => import('@/views/Settings.vue'),
    meta: { requiresAuth: true, requiresAdmin: false },
  },
  // {
  //   path: '/cancel-flow/:view',
  //   name: 'cancelflow',
  //   component: () => import('@/views/BuilderHome.vue'),
  //   meta: { requiresAuth: true, requiresAdmin: false },
  // },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/Test.vue'),
    meta: { requiresAuth: true, requiresAdmin: false },
  },
  {
    path: '/builder/:id',
    name: 'builder',
    component: () => import('@/views/Builder.vue'),
    meta: { layout: LAYOUT.NONE, requiresAuth: true, requiresAdmin: false },
  },
  {
    path: '/syncing',
    name: 'syncing',
    component: () => import('@/components/Cancellation/CancellationAnalytics.vue'),
    meta: { requiresAuth: true, requiresAdmin: false },
  },
  {
    path: '/analytics',
    name: 'analyticsdashboard',
    component: () => import('@/views/AnalyticsHome.vue'),
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: { layout: LAYOUT.NONE, requiresAuth: false, requiresAdmin: false },
  },
  {
    path: '/password-reset',
    name: 'reset',
    component: () => import('@/views/Reset.vue'),
    meta: { layout: LAYOUT.NONE, requiresAuth: false, requiresAdmin: false },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/Register.vue'),
    meta: { layout: LAYOUT.NONE, requiresAuth: false, requiresAdmin: false },
  },
  {
    path: '/get-started',
    redirect: '/get-started/confirm-payment-provider',
  },
  {
    path: '/get-started/:view?',
    name: 'getstarted',
    component: () => import('@/views/GetStarted.vue'),
    meta: { layout: LAYOUT.NONE, requiresAuth: true, requiresAdmin: false },
  },
  {
    path: '/register-old',
    name: 'registerold',
    component: () => import('@/views/Register.vue'),
    meta: { requiresAuth: false, requiresAdmin: false },
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/views/Error.vue'),
    meta: { requiresAuth: false, requiresAdmin: false },
  },
  {
    path: '*',
    redirect: '/overview',
    meta: { requiresAuth: false, requiresAdmin: false },
  },
];

export default routes;
