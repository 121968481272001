import { ISingleSelectOption } from '@/components/UI/Molecules/SingleSelect.vue';
import { ApexChartsTrendData } from '@/components/Vis/Apex/types';
import { IDunningTotalInvoicesDue } from '@/store/types';

export const enum TrendDirection {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
}

export const TREND_DIRECTION_COLOR = {
  [TrendDirection.POSITIVE]: '#3C5CCF',
  [TrendDirection.NEGATIVE]: '#3C5CCF',
};

export const TREND_DIRECTION_ROTATION = {
  [TrendDirection.POSITIVE]: '-90',
  [TrendDirection.NEGATIVE]: '90',
};

export enum AGGREGATE_OPTIONS {
  AMOUNT = 'amount',
  COUNT = 'count',
  PERCENT = 'percent',
}

export const enum DunningHighLevelPerformanceCategories {
  PAYMENTS_RECOVERED = 'PaymentsRecovered',
  SUBSCRIPTIONS_RECOVERED = 'SubscriptionsRecovered',
  ACTIVELY_RECOVERING = 'ActivelyRecovering',
  PERCENTAGE_RECOVERED = 'PercentageRecovered',
  TOP_RECOVERY_METHOD = 'TopRecoveryMethod',
  EMAILS_SENT = 'EmailsSent',
}

export const DUNNING_PERFORMANCE_CATEGORY_TITLE = {
  [DunningHighLevelPerformanceCategories.PAYMENTS_RECOVERED]: 'Payments Recovered',
  [DunningHighLevelPerformanceCategories.SUBSCRIPTIONS_RECOVERED]: 'Subscriptions Recovered',
  [DunningHighLevelPerformanceCategories.ACTIVELY_RECOVERING]: 'Actively Recovering',
  [DunningHighLevelPerformanceCategories.PERCENTAGE_RECOVERED]: 'Percentage Recovered',
  [DunningHighLevelPerformanceCategories.EMAILS_SENT]: 'Emails Sent',
};

type DunningPerformanceCategoryTitleMap = typeof DUNNING_PERFORMANCE_CATEGORY_TITLE;
type DunningPerformanceCategoryTitleType = DunningPerformanceCategoryTitleMap[keyof DunningPerformanceCategoryTitleMap];

export type DunningHighLevelPerforamnceData = {
  key: DunningHighLevelPerformanceCategories;
  title: DunningPerformanceCategoryTitleType | string;
  content: string;
  titleTooltipContent: string;
  trendDirection?: TrendDirection;
  trendData?: ApexChartsTrendData[];
  trendDataFormat?: AGGREGATE_OPTIONS;
  navigationEnabled?: boolean;
  navigationSectionId?: string;
};

export enum RecoveryStatus {
  TOTAL_PAYMENTS_DUE = 'TotalPaymentsDue',
  SUCCESSFUL_INITIALLY = 'SuccessfulInititally',
  RECOVERED = 'Recovered',
  ACTIVELY_RECOVERING = 'ActivelyRecovering',
  UNRECOVERED = 'Unrecovered',
}

export enum RecoveryStatusBreakdown {
  SUCCESSFUL_INITIALLY = 'SuccessfulInititally',
  RECOVERED = 'Recovered',
  ACTIVELY_RECOVERING = 'ActivelyRecovering',
  UNRECOVERED = 'Unrecovered',
}

export const RECOVERY_STATUS_AMOUNT_FIELD: Record<RecoveryStatus, string> = {
  [RecoveryStatus.TOTAL_PAYMENTS_DUE]: 'invoiceTotalUsd',
  [RecoveryStatus.SUCCESSFUL_INITIALLY]: 'totalSuccessfulInitiallyUsd',
  [RecoveryStatus.RECOVERED]: 'totalRecoveredUsd',
  [RecoveryStatus.ACTIVELY_RECOVERING]: 'activelyRecoveringUsd',
  [RecoveryStatus.UNRECOVERED]: 'unrecoveredAmountUsd',
};

export const RECOVERY_STATUS_COUNT_FIELD: Record<RecoveryStatus, keyof IDunningTotalInvoicesDue> = {
  [RecoveryStatus.TOTAL_PAYMENTS_DUE]: 'count',
  [RecoveryStatus.SUCCESSFUL_INITIALLY]: 'totalSuccessfulInitiallyCount',
  [RecoveryStatus.RECOVERED]: 'totalRecoveredCount',
  [RecoveryStatus.ACTIVELY_RECOVERING]: 'activelyRecoveringCount',
  [RecoveryStatus.UNRECOVERED]: 'unrecoveredCount',
};

export const RECOVERY_STATUS_TITLE = {
  [RecoveryStatus.TOTAL_PAYMENTS_DUE]: 'Total Payments Due',
  [RecoveryStatus.SUCCESSFUL_INITIALLY]: 'Successful Initially',
  [RecoveryStatus.RECOVERED]: 'Recovered',
  [RecoveryStatus.ACTIVELY_RECOVERING]: 'Actively Recovering',
  [RecoveryStatus.UNRECOVERED]: 'Unrecovered',
};

export const RECOVERY_STATUS_SHORT_TITLE = {
  [RecoveryStatus.SUCCESSFUL_INITIALLY]: 'Succeeded',
  [RecoveryStatus.RECOVERED]: 'Recovered',
  [RecoveryStatus.ACTIVELY_RECOVERING]: 'Recovering',
  [RecoveryStatus.UNRECOVERED]: 'Unrecovered',
};

type RecoveryStatusTitleMap = typeof RECOVERY_STATUS_TITLE;
type RecoveryStatusTitleType = RecoveryStatusTitleMap[keyof RecoveryStatusTitleMap];

export const RECOVERY_STATUS_DISPLAY_ORDER = {
  [RecoveryStatus.TOTAL_PAYMENTS_DUE]: 1,
  [RecoveryStatus.SUCCESSFUL_INITIALLY]: 2,
  [RecoveryStatus.RECOVERED]: 3,
  [RecoveryStatus.ACTIVELY_RECOVERING]: 4,
  [RecoveryStatus.UNRECOVERED]: 5,
};

type RecoveryStatusDisplayOrderMap = typeof RECOVERY_STATUS_DISPLAY_ORDER;
type RecoveryStatusDisplayOrderType = RecoveryStatusDisplayOrderMap[keyof RecoveryStatusDisplayOrderMap];

export const RECOVERY_STATUS_TOOLTIP_CONTENT = {
  [RecoveryStatus.TOTAL_PAYMENTS_DUE]: 'Total invoices amount (USD) in selected period',
  [RecoveryStatus.SUCCESSFUL_INITIALLY]: 'Total invoice amount (USD) successful on initial payment',
  [RecoveryStatus.RECOVERED]: 'Total invoice amount (USD) successfully recovered',
  [RecoveryStatus.ACTIVELY_RECOVERING]: 'Total invoice amount (USD) in progress of being recovered',
  [RecoveryStatus.UNRECOVERED]: 'Total invoice amount (USD) unrecovered',
};
type RecoveryStatusTooltipContentMap = typeof RECOVERY_STATUS_TOOLTIP_CONTENT;
type RecoveryStatusTooltipContentType = RecoveryStatusTooltipContentMap[keyof RecoveryStatusTooltipContentMap];

export type RecoveryChartFooterData = {
  key: RecoveryStatus;
  order: RecoveryStatusDisplayOrderType;
  title: RecoveryStatusTitleType;
  content: RecoveryStatusTooltipContentType;
  tooltipContent?: string;
  legendColor?: string;
  legendBorderStyle?: string;
  footerText?: string;
  selected?: boolean;
};

export const RECOVERY_STATUS_COLOR = {
  // [RecoveryStatus.TOTAL_PAYMENTS_DUE]: '#FFFFFF',
  [RecoveryStatus.SUCCESSFUL_INITIALLY]: '#3C5CCF',
  [RecoveryStatus.RECOVERED]: '#9A3DBC',
  [RecoveryStatus.ACTIVELY_RECOVERING]: '#28A671',
  [RecoveryStatus.UNRECOVERED]: '#FCF7F3',
};

export const RECOVERY_STATUS_STROKE = {
  // [RecoveryStatus.TOTAL_PAYMENTS_DUE]: '#CAC7F1',
  [RecoveryStatus.SUCCESSFUL_INITIALLY]: '#3C5CCF',
  [RecoveryStatus.RECOVERED]: '#9A3DBC',
  [RecoveryStatus.ACTIVELY_RECOVERING]: '#28A671',
  [RecoveryStatus.UNRECOVERED]: '#9B8E80',
};

export const RECOVERY_STATUS_FILL = {
  // [RecoveryStatus.TOTAL_PAYMENTS_DUE]: '#CAC7F1',
  [RecoveryStatus.SUCCESSFUL_INITIALLY]: '#D8DFF6',
  [RecoveryStatus.RECOVERED]: '#E5DAF9',
  [RecoveryStatus.ACTIVELY_RECOVERING]: '#C7F0DF',
  [RecoveryStatus.UNRECOVERED]: '#FFF',
};

export const RECOVERY_STATUS_PATTERN_FILL = {
  // [RecoveryStatus.TOTAL_PAYMENTS_DUE]: '#CAC7F1',
  [RecoveryStatus.SUCCESSFUL_INITIALLY]: '#D8DFF6',
  [RecoveryStatus.RECOVERED]: '#A1B8FB',
  [RecoveryStatus.ACTIVELY_RECOVERING]: '#7877D7',
  [RecoveryStatus.UNRECOVERED]: '#FFF',
};

export const RECOVERY_STATUS_STOKE_FORMAT = {
  [RecoveryStatus.SUCCESSFUL_INITIALLY]: 0,
  [RecoveryStatus.RECOVERED]: 0,
  [RecoveryStatus.ACTIVELY_RECOVERING]: 0,
  [RecoveryStatus.UNRECOVERED]: 5,
};

export const RECOVERY_STATUS_PATTERN = {
  // [RecoveryStatus.SUCCESSFUL_INITIALLY]: Patterns.HEXAGONS,
  // [RecoveryStatus.RECOVERED]: Patterns.HSTRIPES,
  // [RecoveryStatus.ACTIVELY_RECOVERING]: Patterns.HATCH,
  // [RecoveryStatus.UNRECOVERED]: Patterns.PLAIN,
};

export const PERCENT_OR_NUMBER_OPTIONS: ISingleSelectOption[] = [
  {
    value: 'raw',
    label: 'Total Number (#)',
  },
  {
    value: 'percent',
    label: 'Percentage (%)',
  },
];

export const COUNT_OR_AMOUNT_OPTIONS: ISingleSelectOption[] = [
  {
    value: AGGREGATE_OPTIONS.AMOUNT,
    label: 'By Payment Amount ($)',
  },
  {
    value: AGGREGATE_OPTIONS.COUNT,
    label: 'By # of Payments',
  },
];

export const RECOVERY_STATUS_OPTIONS: ISingleSelectOption[] = [
  {
    value: RecoveryStatus.RECOVERED,
    label: RECOVERY_STATUS_TITLE[RecoveryStatus.RECOVERED],
  },
  {
    value: RecoveryStatus.SUCCESSFUL_INITIALLY,
    label: RECOVERY_STATUS_TITLE[RecoveryStatus.SUCCESSFUL_INITIALLY],
  },
  {
    value: RecoveryStatus.ACTIVELY_RECOVERING,
    label: RECOVERY_STATUS_TITLE[RecoveryStatus.ACTIVELY_RECOVERING],
  },
  {
    value: RecoveryStatus.UNRECOVERED,
    label: RECOVERY_STATUS_TITLE[RecoveryStatus.UNRECOVERED],
  },
];

export enum RecoveryMethod {
  RECOVERED = 'recovered',
  RECOVERED_VIA_EMAIL = 'recoveredViaEmail',
  RECOVERED_VIA_SMS = 'recoveredViaSms',
  RECOVERED_VIA_RETRY_STRATEGY = 'recoveredViaRetryStrategy',
  RECOVERED_VIA_FAILED_PAYMENT_WALL = 'recoveredViaFailedPaymentWall',
  RECOVERED_OTHER = 'recoveredOther',
}

export enum RecoveryMethodOutcomes {
  RECOVERED_VIA_RETRY_STRATEGY = 'recoveredViaRetryStrategy',
  RECOVERED_VIA_EMAIL = 'recoveredViaEmail',
  RECOVERED_VIA_SMS = 'recoveredViaSms',
  RECOVERED_VIA_FAILED_PAYMENT_WALL = 'recoveredViaFailedPaymentWall',
  RECOVERED_OTHER = 'recoveredOther',
}

export enum RecoveredViaRetries {
  RECOVERED_VIA_RETRY_STRATEGY = 'recoveredViaRetryStrategy',
  RECOVERED_VIA_AUTO_RETRY = 'recoveredViaAutoRetry',
}

export const RECOVERY_METHOD_TITLE = {
  [RecoveryMethod.RECOVERED]: 'Total Recovered',
  [RecoveryMethod.RECOVERED_VIA_EMAIL]: 'Emails',
  [RecoveryMethod.RECOVERED_VIA_SMS]: 'SMS',
  [RecoveryMethod.RECOVERED_VIA_RETRY_STRATEGY]: 'Retries',
  [RecoveryMethod.RECOVERED_VIA_FAILED_PAYMENT_WALL]: 'Payment Wall',
  [RecoveryMethod.RECOVERED_OTHER]: 'Recovered (Other)',
};

export const RECOVERY_METHOD_SHORT_TITLE = {
  [RecoveryMethod.RECOVERED]: 'Total',
  [RecoveryMethod.RECOVERED_VIA_EMAIL]: 'Emails',
  [RecoveryMethod.RECOVERED_VIA_SMS]: 'SMS',
  [RecoveryMethod.RECOVERED_VIA_RETRY_STRATEGY]: 'Retries',
  [RecoveryMethod.RECOVERED_VIA_FAILED_PAYMENT_WALL]: 'Payment Wall',
  [RecoveryMethod.RECOVERED_OTHER]: 'Other',
};

type RecoveryMethodTitleMap = typeof RECOVERY_METHOD_TITLE;
type RecoveryMethodTitleType = RecoveryMethodTitleMap[keyof RecoveryMethodTitleMap];

export const RECOVERY_METHOD_DISPLAY_ORDER = {
  [RecoveryMethod.RECOVERED]: 0,
  [RecoveryMethod.RECOVERED_VIA_EMAIL]: 2,
  [RecoveryMethod.RECOVERED_VIA_SMS]: 3,
  [RecoveryMethod.RECOVERED_VIA_RETRY_STRATEGY]: 4,
  [RecoveryMethod.RECOVERED_VIA_FAILED_PAYMENT_WALL]: 5,
  [RecoveryMethod.RECOVERED_OTHER]: 1,
};
type RecoveryMethodDisplayOrderMap = typeof RECOVERY_METHOD_DISPLAY_ORDER;
type RecoveryMethodDisplayOrderType = RecoveryMethodDisplayOrderMap[keyof RecoveryMethodDisplayOrderMap];

export const RECOVERY_METHOD_TOOLTIP_CONTENT = {
  [RecoveryMethod.RECOVERED]: 'Total Recovered Revenue (USD)',
  [RecoveryMethod.RECOVERED_VIA_EMAIL]: 'Total Recovered Revenue (USD) via Churnkey Emails',
  [RecoveryMethod.RECOVERED_VIA_SMS]: 'Total Recovered Revenue (USD) via SMS',
  [RecoveryMethod.RECOVERED_VIA_RETRY_STRATEGY]: 'Total Recovered Revenue (USD) via Auto Retries and Precision Retries',
  [RecoveryMethod.RECOVERED_VIA_FAILED_PAYMENT_WALL]: 'Total Recovered Revenue (USD) via Failed Payment Wall',
  [RecoveryMethod.RECOVERED_OTHER]: 'Total Recovered Revenue (Other)',
};
type RecoveryMethodTooltipContentMap = typeof RECOVERY_METHOD_TOOLTIP_CONTENT;
type RecoveryMethodTooltipContentType = RecoveryMethodTooltipContentMap[keyof RecoveryMethodTooltipContentMap];

export type RecoveryMethodChartFooterData = {
  key: RecoveryMethod | RecoveryMethodOutcomes;
  order: RecoveryMethodDisplayOrderType;
  title: RecoveryMethodTitleType;
  titleInfoTooltip?: string;
  content: RecoveryMethodTooltipContentType;
  tooltipContent?: string;
  legendColor?: string;
  legendBorderStyle?: string;
  footerText?: string;
  selected?: boolean;
  disabled?: boolean;
};

export const RECOVERY_METHOD_COLOR = {
  [RecoveryMethod.RECOVERED_VIA_RETRY_STRATEGY]: '#3C5CCF',
  [RecoveryMethod.RECOVERED_VIA_EMAIL]: '#9A3DBC',
  [RecoveryMethod.RECOVERED_VIA_SMS]: '#dd5da1',
  [RecoveryMethod.RECOVERED_VIA_FAILED_PAYMENT_WALL]: '#28A671',
  [RecoveryMethod.RECOVERED_OTHER]: '#FCF7F3',
};

export const RECOVERY_METHOD_FILL = {
  [RecoveryMethod.RECOVERED_VIA_RETRY_STRATEGY]: '#D8DFF6',
  [RecoveryMethod.RECOVERED_VIA_EMAIL]: '#E5DAF9',
  [RecoveryMethod.RECOVERED_VIA_SMS]: '#f9d3e9',
  [RecoveryMethod.RECOVERED_VIA_FAILED_PAYMENT_WALL]: '#C7F0DF',
  [RecoveryMethod.RECOVERED_OTHER]: '#FCF7F3',
};

export const RECOVERY_METHOD_PATTERN_FILL = {
  [RecoveryMethod.RECOVERED_VIA_RETRY_STRATEGY]: '#8e8adcbf',
  [RecoveryMethod.RECOVERED_VIA_EMAIL]: '#D9BBFD',
  [RecoveryMethod.RECOVERED_VIA_SMS]: '#f9d3e9',
  [RecoveryMethod.RECOVERED_VIA_FAILED_PAYMENT_WALL]: '#FCA4FE',
  [RecoveryMethod.RECOVERED_OTHER]: '#F8EFE6FF',
};

export const RECOVERY_METHOD_STROKE = {
  [RecoveryMethod.RECOVERED_VIA_RETRY_STRATEGY]: '#3C5CCF',
  [RecoveryMethod.RECOVERED_VIA_EMAIL]: '#9A3DBC',
  [RecoveryMethod.RECOVERED_VIA_SMS]: '#dd5da1',
  [RecoveryMethod.RECOVERED_VIA_FAILED_PAYMENT_WALL]: '#28A671',
  [RecoveryMethod.RECOVERED_OTHER]: '#9B8E80',
};

export const RECOVERY_METHOD_STOKE_FORMAT = {
  [RecoveryMethod.RECOVERED_VIA_EMAIL]: 0,
  [RecoveryMethod.RECOVERED_VIA_RETRY_STRATEGY]: 0,
  [RecoveryMethod.RECOVERED_VIA_SMS]: 0,
  [RecoveryMethod.RECOVERED_VIA_FAILED_PAYMENT_WALL]: 0,
  [RecoveryMethod.RECOVERED_OTHER]: 0,
};

export const RECOVERY_METHOD_PATTERN = {
  // [RecoveryMethodOutcomes.RECOVERED_VIA_RETRY_STRATEGY]: 'hstripes',
  // [RecoveryMethodOutcomes.RECOVERED_VIA_EMAIL]: 'dots',
  // [RecoveryMethodOutcomes.RECOVERED_VIA_SMS]: 'dots',
  // [RecoveryMethodOutcomes.RECOVERED_VIA_FAILED_PAYMENT_WALL]: 'hatch',
  // [RecoveryMethodOutcomes.RECOVERED_OTHER]: 'blobs',
};

export const RECOVERY_METHOD_OPTIONS: ISingleSelectOption[] = [
  {
    value: RecoveryMethod.RECOVERED_VIA_RETRY_STRATEGY,
    label: RECOVERY_METHOD_TITLE[RecoveryMethod.RECOVERED_VIA_RETRY_STRATEGY],
  },
  {
    value: RecoveryMethod.RECOVERED_VIA_EMAIL,
    label: RECOVERY_METHOD_TITLE[RecoveryMethod.RECOVERED_VIA_EMAIL],
  },
  {
    value: RecoveryMethod.RECOVERED_VIA_SMS,
    label: RECOVERY_METHOD_TITLE[RecoveryMethod.RECOVERED_VIA_SMS],
  },
  {
    value: RecoveryMethod.RECOVERED_VIA_FAILED_PAYMENT_WALL,
    label: RECOVERY_METHOD_TITLE[RecoveryMethod.RECOVERED_VIA_FAILED_PAYMENT_WALL],
  },
  {
    value: RecoveryMethod.RECOVERED_OTHER,
    label: RECOVERY_METHOD_TITLE[RecoveryMethod.RECOVERED_OTHER],
  },
];

// Message Types and Statuses
export enum MessageType {
  EMAIL = 'email',
  SMS = 'sms',
}

export enum MessageStatus {
  SCHEDULED = 'scheduled',
  SENT = 'sent',
  DELIVERED = 'delivered',
  OPENED = 'opened',
  CLICKED = 'clicked',
  BOUNCED = 'bounced',
  UNDELIVERED = 'undelivered',
  RECOVERED = 'recovered',
}

// Status Display Configuration
export const OVERVIEW_DISPLAY_EMAIL_STATUSES = [MessageStatus.SENT, MessageStatus.OPENED, MessageStatus.CLICKED, MessageStatus.BOUNCED] as const;
export const OVERVIEW_DISPLAY_SMS_STATUSES = [MessageStatus.SENT, MessageStatus.DELIVERED, MessageStatus.CLICKED, MessageStatus.UNDELIVERED] as const;
export const PIVOT_TABLE_EMAIL_STATUSES = [
  MessageStatus.SCHEDULED,
  MessageStatus.SENT,
  MessageStatus.OPENED,
  MessageStatus.CLICKED,
  MessageStatus.RECOVERED,
] as const;
export const PIVOT_TABLE_SMS_STATUSES = [
  MessageStatus.SCHEDULED,
  MessageStatus.SENT,
  MessageStatus.DELIVERED,
  MessageStatus.CLICKED,
  MessageStatus.RECOVERED,
] as const;

export interface MessageTrendData {
  timeFrame: string;
  value: number;
}

export interface MessageStatusData {
  timeFrame: string;
  status: MessageStatus;
  raw: {
    count: number;
    invoiceAmount: number;
    invoiceAmountUsd: number;
  };
  aggregated: {
    count: number;
    invoiceAmount: number;
    invoiceAmountUsd: number;
  };
}

export interface StatusAggregateData {
  total: number;
  trend: MessageTrendData[];
  rawTotal: number;
}

// Display Configuration Interfaces
export interface MessageStatusConfig {
  key: MessageStatus;
  title: string | ((messageType: MessageType) => string);
  tooltipContent: string | ((messageType: MessageType) => string);
  color: string;
  applicableTo: MessageType[];
}

export interface DisplayStatusData {
  key: MessageStatus;
  title: string;
  content: string;
  tooltipContent: string;
  numberTooltipContent: string;
  footerText: string;
  color: string;
  trendData: MessageTrendData[];
  trendDataFormat: AGGREGATE_OPTIONS;
  trendDataColor: string;
  trendDataTimeInterval: string | undefined;
}

// Status Hierarchy Configuration
export const MESSAGE_STATUS_HIERARCHY: Record<MessageStatus, MessageStatus[]> = {
  [MessageStatus.SENT]: [
    MessageStatus.SENT,
    MessageStatus.DELIVERED,
    MessageStatus.OPENED,
    MessageStatus.CLICKED,
    MessageStatus.RECOVERED,
    MessageStatus.BOUNCED,
  ],
  [MessageStatus.DELIVERED]: [MessageStatus.DELIVERED, MessageStatus.OPENED, MessageStatus.CLICKED, MessageStatus.RECOVERED],
  [MessageStatus.OPENED]: [MessageStatus.OPENED, MessageStatus.CLICKED, MessageStatus.RECOVERED],
  [MessageStatus.CLICKED]: [MessageStatus.CLICKED, MessageStatus.RECOVERED],
  [MessageStatus.RECOVERED]: [MessageStatus.RECOVERED],
  [MessageStatus.BOUNCED]: [MessageStatus.BOUNCED],
  [MessageStatus.UNDELIVERED]: [MessageStatus.SENT],
  [MessageStatus.SCHEDULED]: [MessageStatus.SCHEDULED],
} as const;

/**
 * Defines the hierarchy of message statuses for calculating metrics.
 * Each status includes itself and all "child" statuses that should be counted towards its total.
 *
 * For example, SENT includes all possible statuses since every message must be sent first,
 * while CLICKED only includes CLICKED and RECOVERED since these represent the final states
 * of a clicked message.
 *
 */

////////////////////////////////////////////////////////////
// Recovery Progress
////////////////////////////////////////////////////////////

export enum RecoveryProgress {
  RECOVERED = 'Recovered',
  ACTIVELY_RECOVERING = 'ActivelyRecovering',
  PERCENT_RECOVERED = 'PercentRecovered',
}

export const RECOVERY_PROGRESS_AMOUNT_FIELD: Record<string, string> = {
  [RecoveryProgress.RECOVERED]: 'totalRecoveredUsd',
  [RecoveryProgress.ACTIVELY_RECOVERING]: 'activelyRecoveringUsd',
};

export const RECOVERY_PROGRESS_TITLE = {
  [RecoveryProgress.RECOVERED]: 'Recovered',
  [RecoveryProgress.ACTIVELY_RECOVERING]: 'Actively Recovering',
  [RecoveryProgress.PERCENT_RECOVERED]: 'Percent Campaigns Recovered',
};

export const RECOVERY_PROGRESS_SHORT_TITLE = {
  [RecoveryProgress.RECOVERED]: 'Recovered',
  [RecoveryProgress.ACTIVELY_RECOVERING]: 'Recovering',
  [RecoveryProgress.PERCENT_RECOVERED]: 'Percent',
};

type RecoveryProgressTitleMap = typeof RECOVERY_PROGRESS_TITLE;
type RecoveryProgressTitleType = RecoveryProgressTitleMap[keyof RecoveryProgressTitleMap];

export const RECOVERY_PROGRESS_DISPLAY_ORDER = {
  [RecoveryProgress.RECOVERED]: 1,
  [RecoveryProgress.ACTIVELY_RECOVERING]: 2,
  [RecoveryProgress.PERCENT_RECOVERED]: 3,
};

type RecoveryProgressDisplayOrderMap = typeof RECOVERY_PROGRESS_DISPLAY_ORDER;
type RecoveryProgressDisplayOrderType = RecoveryProgressDisplayOrderMap[keyof RecoveryProgressDisplayOrderMap];

export const RECOVERY_PROGRESS_TOOLTIP_CONTENT = {
  [RecoveryProgress.RECOVERED]: 'Total revenue successfully recovered',
  [RecoveryProgress.ACTIVELY_RECOVERING]: 'Total revenue in process of being recovered',
  [RecoveryProgress.PERCENT_RECOVERED]: 'Percent of campaigns recovered',
};
type RecoveryProgressTooltipContentMap = typeof RECOVERY_PROGRESS_TOOLTIP_CONTENT;
type RecoveryProgressTooltipContentType = RecoveryProgressTooltipContentMap[keyof RecoveryProgressTooltipContentMap];

export type RecoveryProgressChartFooterData = {
  key: RecoveryProgress;
  order: RecoveryProgressDisplayOrderType;
  title: RecoveryProgressTitleType;
  content: RecoveryProgressTooltipContentType;
  tooltipContent?: string;
  legendColor?: string;
  legendBorderStyle?: string;
  footerText?: string;
  selected?: boolean;
};

export const RECOVERY_PROGRESS_COLOR = {
  [RecoveryProgress.RECOVERED]: '#3C5CCF',
  [RecoveryProgress.ACTIVELY_RECOVERING]: '#ACB8EB',
  [RecoveryProgress.PERCENT_RECOVERED]: '#FE6CFE',
};

export const RECOVERY_PROGRESS_OPTIONS: ISingleSelectOption[] = [
  {
    value: RecoveryProgress.RECOVERED,
    label: RECOVERY_PROGRESS_TITLE[RecoveryProgress.RECOVERED],
  },
  {
    value: RecoveryProgress.ACTIVELY_RECOVERING,
    label: RECOVERY_PROGRESS_TITLE[RecoveryProgress.ACTIVELY_RECOVERING],
  },
  {
    value: RecoveryProgress.PERCENT_RECOVERED,
    label: RECOVERY_PROGRESS_TITLE[RecoveryProgress.PERCENT_RECOVERED],
  },
];

// Add this after MessageStatusConfig interface
export const MESSAGE_STATUS_CONFIG: Record<MessageStatus, MessageStatusConfig> = {
  [MessageStatus.SENT]: {
    key: MessageStatus.SENT,
    title: 'Sent',
    tooltipContent: 'Total messages sent in selected period',
    color: '#821FA6',
    applicableTo: [MessageType.EMAIL, MessageType.SMS],
  },
  [MessageStatus.DELIVERED]: {
    key: MessageStatus.DELIVERED,
    title: 'Delivered',
    tooltipContent: 'Total messages delivered in selected period',
    color: '#3C5CCF',
    applicableTo: [MessageType.SMS], // Only for SMS
  },
  [MessageStatus.OPENED]: {
    key: MessageStatus.OPENED,
    title: 'Opened',
    tooltipContent: 'Total messages opened in selected period',
    color: '#3C5CCF',
    applicableTo: [MessageType.EMAIL], // Only for Email
  },
  [MessageStatus.CLICKED]: {
    key: MessageStatus.CLICKED,
    title: (messageType: MessageType) => (messageType === MessageType.SMS ? 'Tapped' : 'Clicked'),
    tooltipContent: (messageType: MessageType) => `Total messages ${messageType === MessageType.SMS ? 'tapped' : 'clicked'} in selected period`,
    color: '#27A671',
    applicableTo: [MessageType.EMAIL, MessageType.SMS],
  },
  [MessageStatus.BOUNCED]: {
    key: MessageStatus.BOUNCED,
    title: 'Bounced',
    tooltipContent: 'Total messages bounced in selected period',
    color: '#E0CFBF',
    applicableTo: [MessageType.EMAIL],
  },
  [MessageStatus.UNDELIVERED]: {
    key: MessageStatus.UNDELIVERED,
    title: 'Undelivered',
    tooltipContent: 'Total messages undelivered in selected period',
    color: '#E0CFBF',
    applicableTo: [MessageType.SMS],
  },
  [MessageStatus.RECOVERED]: {
    key: MessageStatus.RECOVERED,
    title: 'Recovered',
    tooltipContent: 'Total messages that led to recovery in selected period',
    color: '#27A671',
    applicableTo: [MessageType.EMAIL, MessageType.SMS],
  },
  [MessageStatus.SCHEDULED]: {
    key: MessageStatus.SCHEDULED,
    title: 'Scheduled',
    tooltipContent: 'Total messages scheduled for future delivery',
    color: '#9B8E80',
    applicableTo: [MessageType.EMAIL, MessageType.SMS],
  },
};

export interface IMessageStats {
  scheduled?: { raw: number; aggregated: number };
  sent?: { raw: number; aggregated: number };
  opened?: { raw: number; aggregated: number };
  clicked?: { raw: number; aggregated: number };
  delivered?: { raw: number; aggregated: number };
  tapped?: { raw: number; aggregated: number };
  recovered?: { raw: number; aggregated: number };
  tooltips?: Record<string, string>;
}

export interface MessageStatusMetrics {
  scheduled: { raw: number; aggregated: number };
  sent: { raw: number; aggregated: number };
  delivered?: { raw: number; aggregated: number };
  opened?: { raw: number; aggregated: number };
  clicked?: { raw: number; aggregated: number };
  recovered?: { raw: number; aggregated: number };
  messageId: string;
}

export interface MessageTooltips {
  scheduled?: string;
  sent?: string;
  delivered?: string;
  opened?: string;
  clicked?: string;
}

export interface MessageWithStats extends IMessageBlueprint {
  stats?: MessageStatusMetrics;
  tooltips?: MessageTooltips;
  openRate?: string;
  clickRate?: string;
  deliveryRate?: string;
}
