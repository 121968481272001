import { unparse } from 'papaparse';
import { descending, range } from 'd3-array';
import { saveAs } from 'file-saver';
import { timeDay, timeHour, timeMonth } from 'd3-time';
import StatsService from '@/api/stats.service';
import { dateExportFormat, dateTimeLabelFormat, getDateEndpoints, monthYearLabel } from '@/helpers/date-utils';
import { pickPaths } from '@/helpers/jsUtils';
import { getCurrencyDivisor } from '@/helpers/currency-utils';
import { flattenSession, IPopulatedSession, LOAD_STATE, SESSION_FETCH_LIMIT } from '../shared';
import * as MUTATE from '../mutation-types';
import * as ACT from '../action-types';

import {
  IAnalyticsSnapshot,
  IBoostStats,
  ICouponStats,
  IDecoratedSession,
  IOfferOutcomeCount,
  IPauseStats,
  IReactivationStats,
  ISegment,
  ISession,
  ISessionFilter,
  ISessionFilterValues,
  ISessionOutcomeCount,
  IState,
  ISurveyResponseCount,
  LoadState,
  CancelFlowMergeField,
  IDataRecord,
} from '../types';
import { IStripeSubscription } from '../stripe-types';
import { adminColumnsToExport } from './export-helpers';

interface IActionContext {
  commit: any;
  dispatch: any;
  state: IState;
  getters: any;
}

const defaultStartDate = timeDay.offset(new Date(), -30);
const defaultDateRange = getDateEndpoints([defaultStartDate, new Date()]);

// available filters for an org
const defaultFilterValues = {
  count: 0,
  trial: [],
  response: [],
  pauseDuration: [],
  couponId: [],
  billingInterval: [],
  planId: [],
  offerType: [],
  segmentId: [],
  minDate: new Date(),
  maxDate: new Date(),
};

const generateFetch = ({
  getLoadState,
  setLoadStateMutation,
  fetchValue,
  setValueMutation,
}: {
  getLoadState: (state: IState) => LoadState;
  setLoadStateMutation: string;
  fetchValue: (options: any) => any;
  setValueMutation: string;
  // eslint-disable-next-line arrow-body-style
}) => {
  return async ({ state, commit }: IActionContext, fetchOptions: any) => {
    try {
      if (getLoadState(state) !== LOAD_STATE.LOADING) {
        commit(setLoadStateMutation, LOAD_STATE.LOADING);
        const raw = await fetchValue(fetchOptions);
        commit(setValueMutation, raw);
        commit(setLoadStateMutation, LOAD_STATE.LOADED);
      }
    } catch (e) {
      console.error('Error in dashboard operation:', e);
      commit(setLoadStateMutation, LOAD_STATE.ERROR_LOADING);
    }
  };
};

const dashboardModule = {
  state: {
    analyticsSnapshots: [],
    analyticsSnapshotsLoadState: LOAD_STATE.UNLOADED,
    boostedRevenueStats: [],
    boostedRevenueStatsLoadState: LOAD_STATE.UNLOADED,
    couponStats: [],
    couponStatsLoadState: LOAD_STATE.UNLOADED,
    pauseStats: null,
    pauseStatsLoadState: LOAD_STATE.UNLOADED,
    reactivationStats: [],
    reactivationStatsLoadState: LOAD_STATE.UNLOADED,
    sessions: [],
    sessionsLoadState: LOAD_STATE.UNLOADED,
    dashboardDateRange: defaultDateRange,
    surveyResponses: [],
    surveyResponsesLoadState: LOAD_STATE.UNLOADED,
    sessionOutcomes: [],
    sessionOutcomesLoadState: LOAD_STATE.UNLOADED,
    offerOutcomes: [],
    offerOutcomesLoadState: LOAD_STATE.UNLOADED,
    recentSaves: [],
    recentSavesLoadState: LOAD_STATE.UNLOADED,
    testSessions: [],
    testSessionsLoadState: LOAD_STATE.UNLOADED,
    recentSessions: [],
    recentSessionsLoadState: LOAD_STATE.UNLOADED,
    sessionFilterValues: defaultFilterValues,
    sessionFilterValuesLoadState: LOAD_STATE.UNLOADED,
    customerProfile: null,
    customerHealth: null,
    customerProfileLoadState: LOAD_STATE.UNLOADED,
    abSessionCounts: {},
    abSessionCountsLoadState: LOAD_STATE.UNLOADED,
    segmentSessionCounts: {},
    segmentSessionCountsLoadState: LOAD_STATE.UNLOADED,
  },

  mutations: {
    [MUTATE.SET_SESSIONS](state: IState, sessions: ISession[]) {
      state.sessions = sessions;
    },
    [MUTATE.SET_SESSIONS_LOAD_STATE](state: IState, loadState: LoadState) {
      state.sessionsLoadState = loadState;
    },
    [MUTATE.SET_DASHBOARD_DATE_RANGE](state: IState, dateRange: [Date, Date]) {
      state.dashboardDateRange = dateRange;
    },
    [MUTATE.SET_ANALYTICS_SNAPSHOTS](state: IState, snapshots: IAnalyticsSnapshot[]) {
      state.analyticsSnapshots = snapshots;
    },
    [MUTATE.SET_ANALYTICS_SNAPSHOTS_LOAD_STATE](state: IState, loadState: LoadState) {
      state.analyticsSnapshotsLoadState = loadState;
    },
    [MUTATE.SET_BOOSTED_REVENUE_STATS](state: IState, stats: IBoostStats[]) {
      state.boostedRevenueStats = stats;
    },
    [MUTATE.SET_BOOSTED_REVENUE_STATS_LOAD_STATE](state: IState, loadState: LoadState) {
      state.boostedRevenueStatsLoadState = loadState;
    },
    [MUTATE.SET_COUPON_STATS](state: IState, stats: ICouponStats[]) {
      state.couponStats = stats;
    },
    [MUTATE.SET_COUPON_STATS_LOAD_STATE](state: IState, loadState: LoadState) {
      state.couponStatsLoadState = loadState;
    },
    [MUTATE.SET_PAUSE_STATS](state: IState, stats: IPauseStats) {
      state.pauseStats = stats;
    },
    [MUTATE.SET_PAUSE_STATS_LOAD_STATE](state: IState, loadState: LoadState) {
      state.pauseStatsLoadState = loadState;
    },
    [MUTATE.SET_REACTIVATION_STATS](state: IState, stats: IReactivationStats[]) {
      state.reactivationStats = stats;
    },
    [MUTATE.SET_REACTIVATION_STATS_LOAD_STATE](state: IState, loadState: LoadState) {
      state.reactivationStatsLoadState = loadState;
    },
    [MUTATE.SET_SURVEY_RESPONSES](state: IState, stats: ISurveyResponseCount[]) {
      state.surveyResponses = stats;
    },
    [MUTATE.SET_SURVEY_RESPONSES_LOAD_STATE](state: IState, loadState: LoadState) {
      state.surveyResponsesLoadState = loadState;
    },
    [MUTATE.SET_SESSION_OUTCOMES](state: IState, stats: ISessionOutcomeCount[]) {
      state.sessionOutcomes = stats;
    },
    [MUTATE.SET_SESSION_OUTCOMES_LOAD_STATE](state: IState, loadState: LoadState) {
      state.sessionOutcomesLoadState = loadState;
    },
    [MUTATE.SET_OFFER_OUTCOMES](state: IState, stats: IOfferOutcomeCount[]) {
      state.offerOutcomes = stats;
    },
    [MUTATE.SET_OFFER_OUTCOMES_LOAD_STATE](state: IState, loadState: LoadState) {
      state.offerOutcomesLoadState = loadState;
    },
    [MUTATE.SET_RECENT_SAVES](state: IState, stats: ISession[]) {
      state.recentSaves = stats;
    },
    [MUTATE.SET_RECENT_SAVES_LOAD_STATE](state: IState, loadState: LoadState) {
      state.recentSavesLoadState = loadState;
    },
    [MUTATE.SET_RECENT_SESSIONS](state: IState, stats: ISession[]) {
      state.recentSessions = stats;
    },
    [MUTATE.SET_RECENT_SESSIONS_LOAD_STATE](state: IState, loadState: LoadState) {
      state.recentSessionsLoadState = loadState;
    },
    [MUTATE.SET_TEST_SESSIONS](state: IState, stats: ISession[]) {
      state.testSessions = stats;
    },
    [MUTATE.SET_TEST_SESSIONS_LOAD_STATE](state: IState, loadState: LoadState) {
      state.testSessionsLoadState = loadState;
    },
    [MUTATE.SET_SESSION_FILTER_VALUES](state: IState, value: ISessionFilterValues) {
      state.sessionFilterValues = value;
    },
    [MUTATE.SET_SESSION_FILTER_VALUES_LOAD_STATE](state: IState, loadState: LoadState) {
      state.sessionFilterValuesLoadState = loadState;
    },
    [MUTATE.SET_CUSTOMER_PROFILE](state: IState, customer: any) {
      state.customerProfile = customer;
    },
    [MUTATE.SET_CUSTOMER_HEALTH](state: IState, health: any) {
      state.customerHealth = health;
    },
    [MUTATE.SET_CUSTOMER_PROFILE_LOAD_STATE](state: IState, loadState: LoadState) {
      state.customerProfileLoadState = loadState;
    },
    [MUTATE.SET_AB_SESSION_COUNTS](state: IState, countMap: any) {
      state.abSessionCounts = countMap;
    },
    [MUTATE.SET_AB_SESSION_COUNTS_LOAD_STATE](state: IState, loadState: LoadState) {
      state.abSessionCountsLoadState = loadState;
    },
    [MUTATE.SET_SEGMENT_SESSION_COUNTS](state: IState, countMap: any) {
      state.segmentSessionCounts = countMap;
    },
    [MUTATE.SET_SEGMENT_SESSION_COUNTS_LOAD_STATE](state: IState, loadState: LoadState) {
      state.segmentSessionCountsLoadState = loadState;
    },
  },

  actions: {
    async [ACT.FETCH_CUSTOMER_PROFILE]({ state, commit }: IActionContext, customerId: string) {
      try {
        commit(MUTATE.SET_CUSTOMER_PROFILE_LOAD_STATE, LOAD_STATE.LOADING);
        const customer = await StatsService.getCustomerProfile(customerId);
        const customerHealth = await StatsService.getCustomerHealth(customerId);
        commit(MUTATE.SET_CUSTOMER_PROFILE, customer);
        if (customerHealth && customerHealth.combinedHealth) {
          commit(MUTATE.SET_CUSTOMER_HEALTH, customerHealth);
        } else {
          commit(MUTATE.SET_CUSTOMER_HEALTH, null);
        }
        commit(MUTATE.SET_CUSTOMER_PROFILE_LOAD_STATE, LOAD_STATE.LOADED);
      } catch (e) {
        console.error('Error in FETCH_CUSTOMER_PROFILE:', e);
        commit(MUTATE.SET_CUSTOMER_PROFILE_LOAD_STATE, LOAD_STATE.ERROR_LOADING);
      }
    },
    async [ACT.FETCH_ANALYTICS_SNAPSHOTS]({ state, commit }: IActionContext) {
      try {
        if (state.analyticsSnapshotsLoadState !== LOAD_STATE.LOADING) {
          commit(MUTATE.SET_ANALYTICS_SNAPSHOTS_LOAD_STATE, LOAD_STATE.LOADING);
          const raw = await StatsService.monthlyAnalytics();
          commit(MUTATE.SET_ANALYTICS_SNAPSHOTS, raw);
          commit(MUTATE.SET_ANALYTICS_SNAPSHOTS_LOAD_STATE, LOAD_STATE.LOADED);
        }
      } catch (e) {
        console.error('Error in FETCH_ANALYTICS_SNAPSHOTS:', e);
        commit(MUTATE.SET_ANALYTICS_SNAPSHOTS_LOAD_STATE, LOAD_STATE.ERROR_LOADING);
      }
    },

    [ACT.FETCH_BOOSTED_REVENUE_STATS]: generateFetch({
      getLoadState: (state: IState) => state.boostedRevenueStatsLoadState,
      setLoadStateMutation: MUTATE.SET_BOOSTED_REVENUE_STATS_LOAD_STATE,
      setValueMutation: MUTATE.SET_BOOSTED_REVENUE_STATS,
      // fetchValue: StatsService.boostedRevenueStats,
      fetchValue: StatsService.materializedBoostedRevenue,
    }),

    [ACT.FETCH_PAUSE_STATS]: generateFetch({
      getLoadState: (state: IState) => state.pauseStatsLoadState,
      setLoadStateMutation: MUTATE.SET_PAUSE_STATS_LOAD_STATE,
      fetchValue: StatsService.pauseStats,
      setValueMutation: MUTATE.SET_PAUSE_STATS_LOAD_STATE,
    }),

    [ACT.FETCH_COUPON_STATS]: generateFetch({
      getLoadState: (state: IState) => state.couponStatsLoadState,
      setLoadStateMutation: MUTATE.SET_COUPON_STATS_LOAD_STATE,
      fetchValue: StatsService.couponStats,
      setValueMutation: MUTATE.SET_COUPON_STATS,
    }),

    [ACT.FETCH_REACTIVATION_STATS]: generateFetch({
      getLoadState: (state: IState) => state.reactivationStatsLoadState,
      setLoadStateMutation: MUTATE.SET_REACTIVATION_STATS_LOAD_STATE,
      fetchValue: StatsService.reactivationStats,
      setValueMutation: MUTATE.SET_REACTIVATION_STATS,
    }),

    [ACT.FETCH_SESSION_OUTCOMES]: generateFetch({
      getLoadState: (state: IState) => state.sessionOutcomesLoadState,
      setLoadStateMutation: MUTATE.SET_SESSION_OUTCOMES_LOAD_STATE,
      fetchValue: StatsService.sessionOutcomes,
      setValueMutation: MUTATE.SET_SESSION_OUTCOMES,
    }),

    [ACT.FETCH_SURVEY_RESPONSES]: generateFetch({
      getLoadState: (state: IState) => state.surveyResponsesLoadState,
      setLoadStateMutation: MUTATE.SET_SURVEY_RESPONSES_LOAD_STATE,
      fetchValue: StatsService.surveyResponses,
      setValueMutation: MUTATE.SET_SURVEY_RESPONSES,
    }),

    [ACT.FETCH_OFFER_OUTCOMES]: generateFetch({
      getLoadState: (state: IState) => state.offerOutcomesLoadState,
      setLoadStateMutation: MUTATE.SET_OFFER_OUTCOMES_LOAD_STATE,
      fetchValue: StatsService.offerOutcomes,
      setValueMutation: MUTATE.SET_OFFER_OUTCOMES,
    }),

    [ACT.FETCH_RECENT_SAVES]: generateFetch({
      getLoadState: (state: IState) => state.recentSavesLoadState,
      setLoadStateMutation: MUTATE.SET_RECENT_SAVES_LOAD_STATE,
      fetchValue: StatsService.recentSaves,
      setValueMutation: MUTATE.SET_RECENT_SAVES,
    }),

    [ACT.FETCH_RECENT_SESSIONS]: generateFetch({
      getLoadState: (state: IState) => state.recentSessionsLoadState,
      setLoadStateMutation: MUTATE.SET_RECENT_SESSIONS_LOAD_STATE,
      fetchValue: StatsService.recentSessions,
      setValueMutation: MUTATE.SET_RECENT_SESSIONS,
    }),

    [ACT.FETCH_TEST_SESSIONS]: generateFetch({
      getLoadState: (state: IState) => state.testSessionsLoadState,
      setLoadStateMutation: MUTATE.SET_TEST_SESSIONS_LOAD_STATE,
      fetchValue: StatsService.testSessions,
      setValueMutation: MUTATE.SET_TEST_SESSIONS,
    }),

    [ACT.FETCH_SESSION_FILTER_VALUES]: generateFetch({
      getLoadState: (state: IState) => state.sessionFilterValuesLoadState,
      setLoadStateMutation: MUTATE.SET_SESSION_FILTER_VALUES_LOAD_STATE,
      fetchValue: StatsService.sessionFilterValues,
      setValueMutation: MUTATE.SET_SESSION_FILTER_VALUES,
    }),

    async [ACT.SESSIONS_GET]({ commit }: IActionContext, { startDate, endDate, limit }: { startDate: Date; endDate: Date; limit?: number }) {
      try {
        commit(MUTATE.SET_SESSIONS_LOAD_STATE, LOAD_STATE.LOADING);
        const raw = await StatsService.listSessions(startDate, endDate, limit);
        commit(MUTATE.SET_SESSIONS, raw);
        commit(MUTATE.SET_SESSIONS_LOAD_STATE, LOAD_STATE.LOADED);
      } catch (e) {
        console.error('Error in SESSIONS_GET:', e);
        commit(MUTATE.SET_SESSIONS_LOAD_STATE, LOAD_STATE.ERROR_LOADING);
      }
    },
    async [ACT.FETCH_AB_SESSION_COUNTS]({ commit, state }: IActionContext) {
      // if already there, don't fetch again
      if ([LOAD_STATE.LOADED, LOAD_STATE.LOADING].includes(state.abSessionCountsLoadState)) {
        return state.abSessionCounts;
      }
      try {
        commit(MUTATE.SET_AB_SESSION_COUNTS_LOAD_STATE, LOAD_STATE.LOADING);
        const raw = await StatsService.fetchABSessionCounts();
        commit(MUTATE.SET_AB_SESSION_COUNTS, raw);
        commit(MUTATE.SET_AB_SESSION_COUNTS_LOAD_STATE, LOAD_STATE.LOADED);
        return raw;
      } catch (e) {
        console.error('Error in FETCH_AB_SESSION_COUNTS:', e);
        commit(MUTATE.SET_AB_SESSION_COUNTS_LOAD_STATE, LOAD_STATE.ERROR_LOADING);
        return {};
      }
    },
    async [ACT.FETCH_SEGMENT_SESSION_COUNTS]({ commit, state }: IActionContext) {
      // if already there, don't fetch again
      if ([LOAD_STATE.LOADED, LOAD_STATE.LOADING].includes(state.abSessionCountsLoadState)) {
        return state.segmentSessionCounts;
      }
      try {
        commit(MUTATE.SET_SEGMENT_SESSION_COUNTS_LOAD_STATE, LOAD_STATE.LOADING);
        const raw = await StatsService.fetchSegmentSessionCounts();
        commit(MUTATE.SET_SEGMENT_SESSION_COUNTS, raw);
        commit(MUTATE.SET_SEGMENT_SESSION_COUNTS_LOAD_STATE, LOAD_STATE.LOADED);
        return raw;
      } catch (e) {
        console.error('Error in FETCH_SEGMENT_SESSION_COUNTS:', e);
        commit(MUTATE.SET_SEGMENT_SESSION_COUNTS_LOAD_STATE, LOAD_STATE.ERROR_LOADING);
        return {};
      }
    },
    async [ACT.DOWNLOAD_SESSIONS]({ getters, state }: IActionContext) {
      const selectedSessions = getters.selectedSessions as ISession[];
      const flattenedSessions = selectedSessions.map((s) => {
        const decoratedSession: IPopulatedSession = {
          ...(s.segmentId && state.segments[s.segmentId] && { segment: state.segments[s.segmentId] }),
          ...s,
        };
        return flattenSession(decoratedSession);
      });
      const headers = [
        'sessionId',
        'sessionDate',
        'sessionResult',
        'surveyResponse',
        'feedback',
        'customerId',
        'customerEmail',
        'customerPlan',
        'customerTrialing',
        'customerSubscriptionStart',
        'customerSegmentId',
        'customerSegmentName',
        'acceptedPauseDuration',
        'acceptedCouponId',
        'acceptedCouponType',
        'acceptedCouponAmount',
        'acceptedCouponDuration',
        'newPlanId',
      ];

      const dataString = unparse(flattenedSessions, {
        columns: headers,
      });
      const blob = new Blob([dataString], { type: 'text/csv;charset=utf-8' });
      const exportDate = getters.dateSelectionExportFormat;
      saveAs(blob, `churnkey-sessions-${exportDate}.csv`);
    },

    async [ACT.DOWNLOAD_SESSIONS_NEXT]({ getters, state }: IActionContext, options: { filter?: ISessionFilter } = {}) {
      const limit = 5000;
      const count = await StatsService.sessionCount({ filter: options.filter || {} });
      if (count > limit) {
        throw new Error(`Download would exceed session limit of ${limit}. Please apply more strict filters to download.`);
      }
      const sessions = await StatsService.filteredSessions({ filter: options.filter || {}, options: { limit, skip: 0 } });
      const flattenedSessions = sessions.map((s) => {
        const decoratedSession: IPopulatedSession = {
          ...(s.segmentId && state.segments[s.segmentId] && { segment: state.segments[s.segmentId] }),
          ...s,
        };
        return flattenSession(decoratedSession);
      });
      const headers = [
        'sessionId',
        'sessionDate',
        'sessionResult',
        'bounced',
        'surveyResponse',
        'followupQuestion',
        'followupResponse',
        'feedback',
        'customerId',
        'customerEmail',
        'customerPlan',
        'customerTrialing',
        'customerSubscriptionStart',
        'customerSegmentId',
        'customerSegmentName',
        'acceptedPauseDuration',
        'acceptedCouponId',
        'acceptedCouponType',
        'acceptedCouponAmount',
        'acceptedCouponDuration',
        'newPlanId',
      ];

      const dataString = unparse(flattenedSessions, {
        columns: headers,
      });
      const blob = new Blob([dataString], { type: 'text/csv;charset=utf-8' });
      const exportDate = getters.dateSelectionExportFormat;
      saveAs(blob, `churnkey-sessions-${exportDate}.csv`);
      return true;
    },
    async [ACT.GET_SESSIONS_AND_BLUEPRINTS](
      { commit, dispatch, state }: IActionContext,
      { startDate, endDate, limit }: { startDate: Date; endDate: Date; limit?: number }
    ) {
      try {
        commit(MUTATE.SET_SESSIONS_LOAD_STATE, LOAD_STATE.LOADING);
        const sessions = await StatsService.listSessions(startDate, endDate, limit);
        const sessionBlueprints: string[] = [...new Set<string>(sessions.map((s) => s.blueprintId))];
        const sessionBlueprintsToLoad = sessionBlueprints.filter((bp) => !state.blueprints[bp]);
        await Promise.all(sessionBlueprintsToLoad.map((bpId) => dispatch(ACT.BLUEPRINT_GET, bpId)));
        commit(MUTATE.SET_SESSIONS, sessions);
        commit(MUTATE.SET_SESSIONS_LOAD_STATE, LOAD_STATE.LOADED);
      } catch (e) {
        console.error('Error in GET_SESSIONS_AND_BLUEPRINTS:', e);
        commit(MUTATE.SET_SESSIONS_LOAD_STATE, LOAD_STATE.ERROR_LOADING);
      }
    },
    async [ACT.EXPORT_DATA](
      { getters }: IActionContext,
      { data, name, fields, csv }: { data: Record<string, any> | Array<any>; name: string; fields?: string[]; csv?: boolean }
    ) {
      const exportDate = getters.dateSelectionExportFormat;
      let dataToExport = data;
      let blob;

      // ARRAY
      if (Array.isArray(dataToExport)) {
        if (fields) {
          dataToExport = data.map((d: any) => pickPaths(d, fields));
        }

        if (csv) {
          const dataString = unparse(dataToExport, {
            columns: fields || Object.keys(dataToExport[0]),
          });
          blob = new Blob([dataString], { type: 'text/csv;charset=utf-8' });
          saveAs(blob, `${name}-${exportDate}.csv`);
        } else {
          blob = new Blob([JSON.stringify(dataToExport, null, 2)], {
            type: 'application/json',
          });
          saveAs(blob, `${name}-${exportDate}.json`);
        }
      } else {
        // OBJECT
        if (fields) {
          dataToExport = pickPaths(data, fields);
        }
        blob = new Blob([JSON.stringify(data, null, 2)], {
          type: 'application/json',
        });
        saveAs(blob, `${name}-${exportDate}.json`);
      }
    },
    async [ACT.RETURN_SESSION_OUTCOMES]({ state, commit }: IActionContext, { filter, breakdown }: { filter?: ISessionFilter; breakdown?: string[] }) {
      return StatsService.sessionOutcomes({ filter, breakdown });
    },
    async [ACT.DOWNLOAD_DATA_RECORDS]({ getters, state }: IActionContext, options: { filter?: ISessionFilter } = {}) {
      const limit = 5000;
      const counts = await StatsService.dataRecordCount({ filter: options.filter || {} });
      const count = counts.reduce((acc, c) => acc + c.count, 0);
      if (count > limit) {
        throw new Error(`Download would exceed session limit of ${limit}. Please apply more strict filters to download.`);
      }
      const records = await StatsService.filteredDataRecords({ filter: options.filter || {}, options: { limit, skip: 0 } });
      const flattenedRecords = records.map((d: IDataRecord) => {
        return {
          uid: d.uid,
          ...d.data,
        };
      });

      if (getters.isAdmin) {
        flattenedRecords.sort((a, b) => descending(a['Account:AnnualSubscriptionValue:$'], b['Account:AnnualSubscriptionValue:$']));
      }

      let columns;
      if (getters.isAdmin) {
        columns = adminColumnsToExport;
      } else {
        columns = [
          ...new Set(
            flattenedRecords.reduce((acc, record) => {
              return [...acc, ...Object.keys(record)];
            }, [] as string[])
          ),
        ];
      }

      const dataString = unparse(flattenedRecords, {
        columns,
      });
      const blob = new Blob([dataString], { type: 'text/csv;charset=utf-8' });
      const exportDate = getters.dateSelectionExportFormat;
      saveAs(blob, `churnkey-data-records-${exportDate}.csv`);
      return true;
    },
  },

  getters: {
    lowData(state: IState) {
      return state.recentSessions.length > 2 && state.recentSessions.length < 10;
    },
    waitForData(state: IState, getters: any) {
      const numRecentSessions = state.recentSessions.length;
      if (getters.daysSinceOrgCreated && numRecentSessions > 2) {
        if (getters.daysSinceOrgCreated < 42 && numRecentSessions < 15) {
          return true;
        }
        if (getters.daysSinceOrgCreated < 21 && numRecentSessions < 30) {
          return true;
        }
      }
      return false;
    },
    installDate(state: IState) {
      if (state.sessionFilterValuesLoadState === LOAD_STATE.LOADED) {
        return state.sessionFilterValues.minDate;
      }
      return null;
    },
    daysSinceInstall(state: IState, getters: any) {
      return getters.installDate && timeDay.count(getters.installDate, new Date());
    },
    dateSelectionLabelFormat(state: IState) {
      const [startDate, endDate] = state.dashboardDateRange;
      return `from ${dateTimeLabelFormat(startDate)} to ${dateTimeLabelFormat(endDate)}`;
    },

    dateSelectionExportFormat(state: IState) {
      const [startDate, endDate] = state.dashboardDateRange;
      return `${dateExportFormat(startDate)}-${dateExportFormat(endDate)}`;
    },

    currentMonthLabel(): string {
      return monthYearLabel(new Date());
    },
    lastMonthLabel(): string {
      return monthYearLabel(timeMonth.offset(new Date(), -1));
    },

    selectedSessions(state: IState, getters: any): ISession[] {
      if (state.dashboardDateRange && state.sessions) {
        return getters.decoratedSessions.filter(
          (session) =>
            // eslint-disable-next-line implicit-arrow-linebreak
            session.mode === 'LIVE' &&
            new Date(session.createdAt) >= state.dashboardDateRange[0] &&
            new Date(session.createdAt) <= state.dashboardDateRange[1]
        );
      }
      return [];
    },

    sessionsExceedLimit(state: IState) {
      return state.sessions && state.sessions.length >= SESSION_FETCH_LIMIT;
    },

    // debouncing for customers with mutliple sessions within 24 hours
    selectedSessionsFinalAction(state: IState, getters: any): ISession[] {
      const customerSessionDate = new Map<string, Date>();
      // sorted from most recent to furthest in history
      const chronologicalSessions = [...getters.selectedSessions].sort((a, b) => descending(a.createdAt, b.createdAt));
      return chronologicalSessions
        .map((s) => {
          if (s.customer && s.customer.email) {
            // check if its a rapid session situation (aborts then discounts then cancels within 24 hours)
            const recentSession = customerSessionDate.get(s.customer.email);
            if (recentSession !== undefined) {
              const hourDiff = timeHour.count(s.createdAt, recentSession);
              customerSessionDate.set(s.customer.email, s.createdAt);
              return hourDiff > 24 ? s : null;
            }
            customerSessionDate.set(s.customer.email, s.createdAt);
            return s;
          }
          return null;
        })
        .filter((s) => s !== null) as IDecoratedSession[];
    },

    // unique segments from selectedSessions
    sessionSegments(state: IState, getters: any): ISegment[] {
      const allSelectedSessions = getters.selectedSessions as IDecoratedSession[];
      const uniqueSegments = allSelectedSessions.reduce((acc, session: IDecoratedSession) => {
        if (session.segmentId) {
          acc.add(session.segmentId);
        }
        return acc;
      }, new Set<string>());
      return Array.from(uniqueSegments).map((id) => state.segments[id]);
    },

    monthlyMetricHistory(state: IState, getters: any) {
      const snapshots = state.analyticsSnapshots;
      const now = new Date();
      const targetMonths = range(0, 24);
      const monthlyHistory = targetMonths.reduce(
        (acc, monthsAgo) => {
          const targetDate = timeMonth.offset(now, -monthsAgo);
          const targetSnapshot = snapshots.find((s) => s.startDate < targetDate && s.endDate > targetDate);

          const boostedRevSnapshot = state.boostedRevenueStats.find((s) => s.invoiceStartDate < targetDate && s.invoiceEndDate > targetDate);
          const combined = {
            ...(targetSnapshot && { companyMetrics: targetSnapshot }),
            ...(boostedRevSnapshot && { boostedRevenue: boostedRevSnapshot }),
          };
          if (Object.keys(combined)) {
            acc[monthsAgo] = combined;
          }
          return acc;
        },
        {} as { [key: number]: { companyMetrics?: IAnalyticsSnapshot; boostedRevenue?: IBoostStats } }
      );
      return monthlyHistory;
    },

    monthsAgoMetric(state: IState, getters: any) {
      return (metric: string, monthsAgo: number): number | null => {
        const v = getters.monthlyMetricHistory[monthsAgo]?.companyMetrics?.[metric];
        return v || null;
      };
    },

    monthsAgoMetricDelta(state: IState, getters: any) {
      return (metric: string, monthsAgo: number, baseline: number): number | null => {
        const current = getters.monthsAgoMetric(metric, baseline);
        const comparison = getters.monthsAgoMetric(metric, monthsAgo);
        if (current && comparison) {
          return comparison / current - 1;
        }
        return null;
      };
    },

    monthsAgoBoostedRevenue(state: IState, getters: any) {
      return (monthsAgo: number): number | null => {
        const v = getters.monthlyMetricHistory[monthsAgo]?.boostedRevenue?.boostedRevenue;
        return v || null;
      };
    },

    monthsAgoBoostedRevenueDelta(state: IState, getters: any) {
      return (monthsAgo: number, baseline: number): number | null => {
        const current = getters.monthsAgoBoostedRevenue(baseline);
        const comparison = getters.monthsAgoBoostedRevenue(monthsAgo);
        if (current && comparison) {
          return current / comparison - 1;
        }
        return null;
      };
    },
    customerProfile(state: IState) {
      return state.customerProfile;
    },
    customerInvoicesTotal(state: IState) {
      if (!state.customerProfile?.activities) {
        return '-';
      }
      try {
        let total = 0;
        let currency = 'usd';
        state.customerProfile.activities.forEach((item: any) => {
          if (item.timelineType === 'invoice') {
            total += item.amountPaid;
            currency = item.currency ? item.currency : 'usd';
          }
        });
        const amount = new Intl.NumberFormat(undefined, {
          style: 'currency',
          currency: currency || 'usd',
        }).format(total / 100);
        return amount;
      } catch (e) {
        console.error('Error in customerInvoicesTotal:', e);
        return '-';
      }
    },
    // Formating customer subscription as e.g $39 per month
    customerSubscriptionPriceToString(state: IState, getters: any) {
      if (!state.customerProfile?.customer?.decorated) {
        return '-';
      }
      const priceData = state.customerProfile.customer.decorated.customerPrice;
      if (priceData) {
        if (priceData && priceData.amount) {
          const amount = new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: priceData.currency || 'usd',
          }).format(priceData.amount / getCurrencyDivisor(priceData.currency));
          let recurring = '';
          if (priceData.type === 'recurring') {
            const intervalCount = priceData.intervalCount || 1;
            const interval = priceData.interval;
            recurring = `/${interval}`;
            if (intervalCount !== 1) {
              recurring = `/${intervalCount} ${interval}s`;
            }
          }
          return `${amount}${recurring}`;
        }
      }
      return '-';
    },
    // for profile page, decorated subscriptions
    customerSubscriptions(state: IState) {
      const subs = state.customerProfile?.customer?.subscriptions?.data || ([] as IStripeSubscription[]);
      return subs.map((sub: IStripeSubscription) => {
        // get label based on subscription plan
        const plan = sub.plan;

        const nickname = plan.nickname as string;

        const currency = sub.currency as string;
        const divisor = getCurrencyDivisor(currency);

        const amount = plan.amount / divisor;
        const formattedAmount = new Intl.NumberFormat(undefined, {
          style: 'currency',
          currency: currency || 'usd',
        }).format(amount);
        const interval = plan.interval;
        const intervalCount = plan.interval_count;
        const intervalLabel = intervalCount === 1 ? interval : `${intervalCount} ${interval}s`;
        const priceLabel = `${formattedAmount}/${intervalLabel}`;
        return {
          id: sub.id,
          nickname,
          priceLabel,
          status: sub.status,
        };
      });
    },
    cancelFlowMergeFields(): CancelFlowMergeField[] {
      return [
        {
          label: 'Customer Full Name',
          id: 'CUSTOMER_NAME',
          fallback: '',
        },
        {
          label: 'Customer First Name',
          id: 'CUSTOMER_NAME.FIRST',
          fallback: '',
        },
        {
          label: 'Customer Last Name',
          id: 'CUSTOMER_NAME.LAST',
          fallback: '',
        },
        {
          label: 'Subscription Age',
          id: 'SUBSCRIPTION_AGE',
          fallback: '',
        },
        {
          label: 'Subscription Start Date',
          id: 'SUBSCRIPTION_START_DATE',
          fallback: '',
        },
        {
          label: 'Subscription Price',
          id: 'SUBSCRIPTION_PRICE',
          fallback: '',
        },
        // {
        //   label: 'Billing Interval',
        //   id: 'BILLING_INTERVAL',
        //   fallback: '',
        // },
        // {
        //   label: 'Price',
        //   id: 'PRICE',
        //   fallback: '',
        // },
      ];
    },
  },
};

export default dashboardModule;
