import { IUser } from '@/store/types';
import Axios from '@/helpers/axios-wrapper';

export default {
  /**
   * Get Current Logged In Admin User
   */
  getMe(): Promise<IUser> {
    return Axios.get('/api/users/me');
  },

  /**
   * @param {String} authCode is the Stripe provided token
   * @param {String} userId is the authenticating user's _id
   */
  stripeAuthCallback(authCode: string, userId: string) {
    return Axios.get(`/auth/stripe/callback?code=${authCode}&user=${userId}`);
  },

  /**
   * @param {String} code is the Slack provided code
   */
  slackConnect(code: string, redirectUri: string) {
    return Axios.get(`/auth/slack/callback?code=${code}&redirect_uri=${redirectUri}`);
  },

  /**
   * @param {String} token fast track token we received as query param
   */
  verifyFastTrackToken(token: string) {
    return Axios.get(`/auth/fasttrack/verify?token=${token}`);
  },

  /**
   * @param {String} token magic link token we received as query param
   */
  verifyMagicLinkToken(token: string): Promise<{ token: string }> {
    return Axios.get(`/auth/magiclink/verify?token=${token}`);
  },
};
