import Axios from '@/helpers/axios-wrapper';
import CampaignService from './campaign.service';

const RESOURCE = '/api/reactivation';

export default {
  /**
   * @param {String} subscriptionId
   */
  generateTestLink({
    subscriptionId,
    customerId,
    mode,
    campaignBlueprintId,
    emailBlueprintId,
  }: {
    subscriptionId?: string;
    customerId?: string;
    mode: string;
    campaignBlueprintId?: string;
    emailBlueprintId?: string;
  }): Promise<{ url: string }> {
    return Axios.post<{ url: string }>(`${RESOURCE}/test-link`, { subscriptionId, customerId, mode, campaignBlueprintId, emailBlueprintId });
  },
  ...CampaignService(RESOURCE),
};
